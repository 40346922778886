import { CurrencyPipe } from '@angular/common';
import {
  Component,
  OnDestroy,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { NgApexchartsModule } from 'ng-apexcharts';
import { Subscription } from 'rxjs';
import { Appointment, ImportsModule, KpiCardI, MemberService } from 'shared';
import { DashboardChartsComponent } from './dashboard-charts/dashboard-charts.component';
import { AuthService } from '../../../../../shared/src/lib/services/auth/auth.service';
import { TableModule } from 'primeng/table';
import { BaseDashboardComponent } from '../../../../../shared/src/lib/base/base-dashboard/base-dashboard.component';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    CurrencyPipe,
    ImportsModule,
    NgApexchartsModule,
    DashboardChartsComponent,
    TableModule,
    BaseDashboardComponent,
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent
  extends BaseDashboardComponent
  implements OnInit, OnDestroy
{
  rangeDates: Date[] | undefined;
  chartOptions: any;

  loading: boolean = false;
  value: any = 1;
  statuses: any;
  representatives: any;

  subscriptions = new Subscription();

  fullfilledAppointments: Appointment[] = [];
  pendingAppointments: Appointment[] = [];
  dataLoaded: boolean = false;
  doctor: any;
  kpis: WritableSignal<KpiCardI[]> = signal([
    {
      header: ['Ασθενείς'],
      headerIcon: 'pi pi-user',
      dataCount: 350,
      pipe: 'number',
      button: { routerLink: '/manage-patients', label: 'Όλοι οι ασθενείς' },
    },
    {
      header: ['Επισκέψεις'],
      headerIcon: 'pi pi-users',
      dataCount: 1200,
      pipe: 'number',
      button: { routerLink: '/calendar', label: 'Όλες οι επισκέψεις' },
    },
    {
      header: ['Συνταγογραφήσεις'],
      headerIcon: 'pi pi-file-plus',
      dataCount: 650,
      pipe: 'number',
      button: { routerLink: '/prescriptions', label: 'Όλες οι συνταγές' },
    },
    {
      header: ['Γνωματεύσεις'],
      headerIcon: 'pi pi-book',
      dataCount: 340,
      pipe: 'number',
      button: { routerLink: '/', label: 'Όλες οι γνωματεύσεις' },
    },
  ]);

  collection = [
    {
      customer: 'Αλέξανδρος Σταύρου',
      receipt: 1200.0,
      balance: 300.0,
    },
    {
      customer: 'Μαρία Παπαδοπούλου',
      receipt: 950.0,
      balance: 450.0,
    },
    {
      customer: 'Γιάννης Νικολάου',
      receipt: 875.0,
      balance: 200.0,
    },
    {
      customer: 'Ελένη Γεωργίου',
      receipt: 800.0,
      balance: 350.0,
    },
    {
      customer: 'Παναγιώτης Κωνσταντίνου',
      receipt: 1300.0,
      balance: 500.0,
    },
    {
      customer: 'Σοφία Δημητρίου',
      receipt: 1100.0,
      balance: 250.0,
    },
    {
      customer: 'Δημήτρης Αντωνίου',
      receipt: 950.0,
      balance: 400.0,
    },
    {
      customer: 'Ανδρέας Ζαφειρίου',
      receipt: 700.0,
      balance: 150.0,
    },
    {
      customer: 'Κατερίνα Παναγιώτου',
      receipt: 1050.0,
      balance: 300.0,
    },
  ];

  records = [
    {
      patient: 'Γεώργιος Παπαδόπουλος',
      date: '2023-11-07',
      charge: 150.0,
      credit: 100.0,
      description: 'Πλήρης αιματολογικός έλεγχος για προληπτική αξιολόγηση.',
    },
    {
      patient: 'Ελένη Μαρίνου',
      date: '2023-11-06',
      charge: 200.0,
      credit: 50.0,
      description:
        'Τακτικός ετήσιος έλεγχος, περιλαμβάνει αιματολογικά και καρδιογράφημα.',
    },
    {
      patient: 'Νικόλαος Παπανικολάου',
      date: '2023-11-05',
      charge: 300.0,
      credit: 200.0,
      description: 'Ακτινογραφία θώρακα λόγω παρατεταμένης δύσπνοιας και βήχα.',
    },
    {
      patient: 'Μιχάλης Δημητρίου',
      date: '2023-11-04',
      charge: 250.0,
      credit: 150.0,
      description: 'Υπέρηχος άνω κοιλίας για έλεγχο ηπατικής λειτουργίας.',
    },
    {
      patient: 'Κατερίνα Παναγοπούλου',
      date: '2023-11-03',
      charge: 180.0,
      credit: 80.0,
      description: 'Μαγνητική τομογραφία κεφαλής λόγω έντονων πονοκεφάλων.',
    },
    {
      patient: 'Παναγιώτης Ζαφειρίου',
      date: '2023-11-02',
      charge: 220.0,
      credit: 100.0,
      description: 'Επανέλεγχος αιματολογικών δεικτών μετά από θεραπεία.',
    },
    {
      patient: 'Ανδρέας Κωνσταντίνου',
      date: '2023-11-01',
      charge: 190.0,
      credit: 90.0,
      description: 'Προληπτικός έλεγχος καρδιολογικών δεικτών.',
    },
    {
      patient: 'Σοφία Λαμπροπούλου',
      date: '2023-10-31',
      charge: 270.0,
      credit: 150.0,
      description: 'Καρδιογράφημα λόγω αίσθησης αρρυθμίας και κούρασης.',
    },
  ];

  constructor(
    private authService: AuthService,
    private memberService: MemberService
  ) {
    super();
  }
  ngOnInit() {
    this.fetchData();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  fetchData() {
    // let currentUserData;
    const user = JSON.parse(localStorage.getItem('token')!);
    const memberServiceSubscription$ = this.memberService
      .getEntityByUsername(user.userName)
      .subscribe({
        next: (user) => {
          this.doctor = user.data;
          this.dataLoaded = true;
          this.authService.setUser(user);
        },
      });

    this.subscriptions.add(memberServiceSubscription$);
  }

  getFilteredDates(event: Event) {
    console.log(event);
  }

  getTypeBackgroundColor(type: string) {
    if (type.toLowerCase() == 'video call') {
      return 'bg-blue-100 p-1 border-round';
    } else {
      return 'bg-yellow-100 p-1 border-round';
    }
  }

  getReasonSeverity(severity: any) {
    if (severity == 1) {
      return 'border-circle w-1rem h-1rem m-2 bg-blue-200 font-bold flex align-items-center justify-content-center"';
    } else if (severity == 2) {
      return 'border-circle w-1rem h-1rem m-2 bg-yellow-200 font-bold flex align-items-center justify-content-center';
    } else {
      return 'border-circle w-1rem h-1rem m-2 bg-red-200 font-bold flex align-items-center justify-content-center';
    }
  }
  getSeverity(
    arg0: any
  ):
    | 'success'
    | 'secondary'
    | 'info'
    | 'warning'
    | 'danger'
    | 'contrast'
    | undefined {
    throw new Error('Method not implemented.');
  }
}
