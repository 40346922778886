import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import {
  AppLayoutComponent,
  AuthGuard,
  InboxComponent,
  LoginComponent,
  NotfoundComponent,
  RegisterComponent,
} from 'shared';
import { ManagePatientsComponent } from './pages/manage-patients/manage-patients.component';
import { PatientDetailsComponent } from './pages/manage-patients/patient-details/patient-details.component';
import { PrescriptionReferralComponent } from './pages/prescription-referral/prescription-referral.component';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          component: AppLayoutComponent,
          children: [
            {
              path: '',
              data: { breadcrumb: 'Προφίλ' },
              component: DashboardComponent,
              canActivate: [AuthGuard],
            },
            {
              path: 'calendar',
              data: { breadcrumb: 'Ημερολόγιο​' },
              component: CalendarComponent,
              canActivate: [AuthGuard],
            },
            {
              path: 'manage-patients',
              data: { breadcrumb: 'Βάση Ασθενών' },
              component: ManagePatientsComponent,
              canActivate: [AuthGuard],
            },
            {
              path: 'patient-details/:id',
              data: { breadcrumb: '' },
              component: PatientDetailsComponent,
              canActivate: [AuthGuard],
            },
            {
              path: 'prescriptions',
              component: PrescriptionReferralComponent,
              canActivate: [AuthGuard],
            },
            {
              path: 'messages',
              component: InboxComponent,
              canActivate: [AuthGuard],
            },
          ],
        },
        {
          path: 'notfound',
          component: NotfoundComponent,
          canActivate: [AuthGuard],
        },
        { path: 'login', component: LoginComponent },
        { path: 'register', component: RegisterComponent },
        { path: '**', redirectTo: '/notfound' },
      ],
      {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        onSameUrlNavigation: 'reload',
      }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
