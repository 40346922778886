import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '@shared/environments';
import { MemberService } from '../member/member.service';

interface AuthResponse {
  userName: string;
  jwtToken: string;
  expiresIn: number;
  timeStamp: string;
  serviceOutcome: number;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl = `${environment.api}baseAuthentication`;
  private api = `${environment.api}`;
  private authToken: AuthResponse | null = null;

  private currentUserSubject = new ReplaySubject<any>(1);
  currentUser$ = this.currentUserSubject.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    private memberService: MemberService
  ) {}

  fetchUser(token: any) {
    // debugger;
    if (token == null) {
      this.currentUserSubject.next(null);
      return of(null);
    }

    return this.memberService
      .getEntityByUsername(token.userName, token.jwtToken)
      .pipe(
        map((user) => {
          console.log('Fetched', user);
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  private getAuthTokenFromLocalStorage(): AuthResponse | null {
    const tokenJson = localStorage.getItem('token');
    return tokenJson ? JSON.parse(tokenJson) : null;
  }

  private saveAuthTokenToLocalStorage(token: AuthResponse): void {
    localStorage.setItem('token', JSON.stringify(token));
  }

  private clearAuthToken(): void {
    localStorage.removeItem('token');
  }

  login(username: string, password: string): Observable<any> {
    return this.http
      .post<AuthResponse>(`${this.apiUrl}/login`, { username, password })
      .pipe(
        switchMap((token) => {
          console.log(token);
          this.authToken = token;
          this.saveAuthTokenToLocalStorage(token);

          // Chain the fetchUser observable
          return this.fetchUser(token).pipe(
            map(() => token) // Return the token after fetchUser completes
          );
        })
      );
  }

  logout(): void {
    this.clearAuthToken();
    this.authToken = null;
    this.currentUserSubject.next(null);
    this.router.navigate(['login']);
  }

  setUser(user: any) {
    this.currentUserSubject.next(user);
  }

  isAuthenticated(): boolean {
    const token = this.authToken || this.getAuthTokenFromLocalStorage();
    return token ? !this.isTokenExpired(token) : false;
  }

  private isTokenExpired(token: AuthResponse): boolean {
    try {
      const expiry =
        new Date(token.timeStamp).getTime() / 1000 + token.expiresIn;
      return Math.floor(new Date().getTime() / 1000) >= expiry;
    } catch (e) {
      console.error('Error parsing token:', e);
      return true;
    }
  }
}
