import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';


import {
  AppLayoutComponent,
  AuthGuard,
  InboxComponent,
  LoginComponent,
  NotfoundComponent,
  RegisterComponent,
  SettingsComponent,
} from 'shared';




@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          component: AppLayoutComponent,
          children: [
            {
              path: '',
              data: { breadcrumb: 'Προφίλ' },
              loadComponent: () => import('./pages/dashboard/dashboard.component').then(m => m.DashboardComponent),
              canActivate: [AuthGuard],
            },
            {
              path: 'calendar',
              data: { breadcrumb: 'Ημερολόγιο​' },
              loadComponent: () => import('./pages/calendar/calendar.component').then(m => m.CalendarComponent),
              canActivate: [AuthGuard],
            },
            {
              path: 'manage-patients',
              data: { breadcrumb: 'Βάση Ασθενών' },
              loadComponent: () => import('./pages/manage-patients/manage-patients.component').then(m => m.ManagePatientsComponent),
              canActivate: [AuthGuard],
            },
            {
              path: 'patient-details/:id',
              data: { breadcrumb: '' },
              loadComponent: () => import('./pages/manage-patients/patient-details/patient-details.component').then(m => m.PatientDetailsComponent),
              canActivate: [AuthGuard],
            },
            {
              path: 'prescriptions',
              loadComponent: () => import('./pages/prescription-referral/prescription-referral.component').then(m => m.PrescriptionReferralComponent),
              canActivate: [AuthGuard],
            },
            {
              path: 'messages',
              component: InboxComponent,
              canActivate: [AuthGuard],
            },
            {
              path: 'settings',
              component: SettingsComponent,
              canActivate: [AuthGuard],
            },
          ],
        },
        {
          path: 'notfound',
          component: NotfoundComponent,
          canActivate: [AuthGuard],
        },
        { path: 'login', component: LoginComponent },

        { path: 'register', component: RegisterComponent },
        { path: '**', redirectTo: '/notfound' },
      ],
      {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        onSameUrlNavigation: 'reload',
      }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
