<div class="grid row-gap-2">
  <div class="col-12 grid">
    <div class="xl:col-3 sm:col-6 col-12">
      <div
        class="card flex flex-column justify-content-between p-0 text-center lg:gap-3 gap-1 cursor-pointer"
      >
        <span class="font-bolder text-xl mt-3">Συνταγογραφήσεις</span>
        <span class="font-bold lg:text-6xl text-2xl mt-3 text-primary">{{
          67 | number
        }}</span>
        <div
          class="flex justify-content-center align-items-center gap-3 bg-primary-700 border-round-bottom-3xl p-1"
        >
          <span class="bg-primary-700 border-none text-white font-bold text-xl">
            Συνταγογράφηση
          </span>
          <i class="pi pi-plus text-white font-bold text-xl"></i>
        </div>
      </div>
    </div>
    <div class="xl:col-3 sm:col-6 col-12">
      <div
        class="card flex flex-column justify-content-between p-0 text-center lg:gap-3 gap-1 cursor-pointer"
      >
        <span class="font-bolder text-xl mt-3">Παραπομπές</span>
        <span class="font-bold lg:text-6xl text-2xl mt-3 text-primary">{{
          80 | number
        }}</span>
        <div
          class="flex justify-content-center align-items-center gap-3 bg-primary-700 border-round-bottom-3xl p-1"
        >
          <span class="bg-primary-700 border-none text-white font-bold text-xl">
            Παραπομπή
          </span>
          <i class="pi pi-plus text-white font-bold text-xl"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="card">
      <p-tabView class="mt-3">
        <p-tabPanel header="Συνταγογραφήσεις">
          <div
            class="flex md:flex-row flex-column justify-content-between flex-wrap align-items-center my-2"
          >
            <h6 class="my-3 text-primary">Συνταγογραφήσεις​</h6>
            <!-- <button
              pButton
              pRipple
              label="Προσθήκη Διάγνωσης"
              class="p-button-success"
            ></button> -->
          </div>
          <p-table [value]="prescriptions">
            <ng-template pTemplate="header">
              <tr>
                <th>Ασθενής</th>
                <th>Ιατρός</th>
                <th>Ημ. Συνταγής</th>
                <th>Ημ. Λήξης Συνταγής</th>
                <th>Φάρμακο</th>
                <th>Ποσότητα</th>
                <th>Κόστος</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-prescription>
              <tr>
                <td>{{ prescription.patient }}</td>
                <td>{{ prescription.doctor }}</td>
                <td>
                  {{ prescription.prescriptionDate | date : "dd/MM/yyyy" }}
                </td>
                <td>{{ prescription.expirationDate | date : "dd/MM/yyyy" }}</td>
                <td>{{ prescription.medication }}</td>
                <td>{{ prescription.quantity }}</td>
                <td>{{ prescription.cost }}</td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
        <p-tabPanel header="Παραπεμπτικά">
          <div
            class="flex md:flex-row flex-column justify-content-between flex-wrap align-items-center my-2"
          >
            <h6 class="my-3 text-primary">Παραπεμπτικά​</h6>
            <!-- <button
              pButton
              pRipple
              label="Προσθήκη Διάγνωσης"
              class="p-button-success"
            ></button> -->
          </div>
          <p-table [value]="history">
            <ng-template pTemplate="header">
              <tr>
                <th>Ασθενής</th>
                <th>Ιατρός</th>
                <th>Ημ. Συνταγής</th>
                <th>Ημ. Λήξης Συνταγής</th>
                <th>Επιλογή Εξέτασης</th>
                <th>Σημειώσεις</th>
                <th>Κόστος</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
              <tr>
                <td>{{ item.patient }}</td>
                <td>{{ item.doctor }}</td>
                <td>{{ item.prescriptionDate | date : "dd/MM/yyyy" }}</td>
                <td>{{ item.expirationDate | date : "dd/MM/yyyy" }}</td>
                <td>{{ item.examinationType }}</td>
                <td>{{ item.notes }}</td>
                <td>{{ item.cost }}</td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>
