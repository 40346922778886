@if(dataLoaded){

<div class="grid row-gap-2">
  <div class="col-12">
    <div class="grid">
      <div class="col-12 sm:col-6 xl:col-3">
        <div
          class="card bg-primary text-0 flex flex-column align-items-center justify-content-center pt-4 h-full"
          style="min-width: 17rem"
        >
          <h5 class="mb-1 m-0 text-white text-center p-3">
            <i class="pi pi-user font-bold text-2xl text-white mr-2"></i
            >Ασθενείς
          </h5>
          <h1 class="mb-1 m-0 text-white">350</h1>
          <button
            pButton
            class="text-gray-800 text-xl w-full flex justify-content-center"
            style="background-color: white"
            [routerLink]="['/manage-patients']"
          >
            Όλοι οι ασθενείς
          </button>
        </div>
      </div>
      <div class="col-12 sm:col-6 xl:col-3">
        <div
          class="card bg-primary text-white flex flex-column align-items-center justify-content-center pt-4 h-full"
          style="min-width: 17rem"
        >
          <h5 class="mb-1 m-0 text-white text-center p-3">
            <i class="pi pi-users font-bold text-2xl text-white mr-2"></i
            >Επισκέψεις
          </h5>
          <h1 class="mb-1 m-0 text-white">1200</h1>
          <button
            pButton
            class="text-gray-800 text-xl w-full flex justify-content-center"
            style="background-color: white"
            [routerLink]="['/calendar']"
          >
            Όλες οι επισκέψεις
          </button>
        </div>
      </div>
      <div class="col-12 sm:col-6 xl:col-3">
        <div
          class="card bg-primary text-white flex flex-column align-items-center justify-content-center pt-4 h-full"
          style="min-width: 17rem"
        >
          <h5 class="mb-1 m-0 text-white text-center p-3">
            <i class="pi pi-file-plus font-bold text-2xl text-white mr-2"></i
            >Συνταγογραφήσεις
          </h5>
          <h1 class="mb-1 m-0 text-white">650</h1>
          <button
            pButton
            class="text-gray-800 text-xl w-full flex justify-content-center"
            style="background-color: white"
            [routerLink]="['/prescriptions']"
          >
            Όλες οι συνταγές
          </button>
        </div>
      </div>
      <div class="col-12 sm:col-6 xl:col-3">
        <div
          class="card bg-primary text-white flex flex-column align-items-center justify-content-center pt-4 h-full"
          style="min-width: 17rem"
        >
          <h5 class="mb-1 m-0 text-white text-center p-3">
            <i class="pi pi-book font-bold text-2xl text-white mr-2"></i
            >Γνωματεύσεις
          </h5>
          <h1 class="mb-1 m-0 text-white">340</h1>
          <button
            pButton
            class="text-gray-800 text-xl w-full flex justify-content-center"
            style="background-color: white"
          >
            Όλες οι γνωματεύσεις
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="lg:col-6 col-12">
    <div class="card h-full">
      <app-dashboard-charts chartCase="monthlyChart"></app-dashboard-charts>
    </div>
  </div>
  <div class="lg:col-6 col-12">
    <div class="card h-full">
      <h5>Λίστα εισπράξεων</h5>
      <p-table
        [value]="collection"
        responsiveLayout="scroll"
        [paginator]="true"
        [rows]="5"
        [tableStyle]="{ 'min-width': '50rem' }"
      >
        <ng-template pTemplate="header">
          <tr>
            <th>Πελάτης</th>
            <th>Είσπραξη</th>
            <th>Υπόλοιπο</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-record>
          <tr>
            <td>{{ record.customer }}</td>
            <td>{{ record.receipt | currency : "EUR" }}</td>
            <td>{{ record.balance | currency : "EUR" }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <div class="lg:col-12 col-12">
    <div class="card">
      <h5>Λίστα τελευταίων συναλλαγών</h5>
      <p-table
        [value]="records"
        responsiveLayout="scroll"
        [paginator]="true"
        [rows]="5"
        [tableStyle]="{ 'min-width': '50rem' }"
      >
        <ng-template pTemplate="header">
          <tr>
            <th>Ασθενής</th>
            <th>Ημερομηνία</th>
            <th>Χρέωση</th>
            <th>Πίστωση</th>
            <th>Αιτιολογία</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-record>
          <tr>
            <td>{{ record.patient }}</td>
            <td>{{ record.date | date : "dd/MM/yyyy" }}</td>
            <td>{{ record.charge | currency : "EUR" }}</td>
            <td>{{ record.credit | currency : "EUR" }}</td>
            <td>{{ record.description }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
}@else {
<div
  class="col-12 flex justify-content-center align-items-center h-100em w-100em"
>
  <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
</div>

}
