import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid'; // Import dayGrid plugin
import timeGridPlugin from '@fullcalendar/timegrid'; // import TimeGridPlugin
import interactionPlugin from '@fullcalendar/interaction'; // Import interaction plugin if needed
import { CalendarOptions } from '@fullcalendar/core';
import {
  Appointment,
  AppointmentsService,
  ImportsModule,
  ModalMessage,
} from 'shared';
import { FullCalendarModule } from '@fullcalendar/angular';

import { forkJoin, Subscription, switchMap } from 'rxjs';
import { TabViewModule } from 'primeng/tabview';
import { CellEditor, Table, TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../../shared/src/lib/services/auth/auth.service';
import { BadgeModule } from 'primeng/badge';
import { CalendarModule } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { CommonModule, DatePipe } from '@angular/common';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { TagModule } from 'primeng/tag';
import { RippleModule } from 'primeng/ripple';
import { MenuItem } from 'primeng/api';
import { MenubarModule } from 'primeng/menubar';
import { InputTextModule } from 'primeng/inputtext';
import { Menu, MenuModule } from 'primeng/menu';
import { AppointmentStatus } from '../../../../../shared/src/public-api';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  standalone: true,
  imports: [
    TabViewModule,
    DialogModule,
    FullCalendarModule,
    BadgeModule,
    CalendarModule,
    ButtonModule,
    FormsModule,
    MultiSelectModule,
    DatePipe,
    TableModule,
    InputTextareaModule,
    DropdownModule,
    TagModule,
    RippleModule,
    CommonModule,
    MenubarModule,
    InputTextModule,
    MenuModule,
  ],
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, OnDestroy {
  tags: any;
  showDialog: boolean = false;
  clickedEvent: any = null;
  view: string = '';
  calendarOptions: any = {
    plugins: [dayGridPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    dateClick: this.onDateSelect.bind(this),
    weekends: false,
  };

  changedEvent: any;
  patient_history: any[] = [];
  confirmed_patient_history: any[] = [];
  pendingAppointments: any[] = [];
  subscriptions = new Subscription();

  successVisible: boolean = false;
  successDeleteVisible: boolean = false;
  errorVisible: boolean = false;

  modalMessages = ModalMessage;

  editAppointmentDialog: boolean = false;
  availableDay: any;
  availableTimes: any;
  selectedTime: any;

  appointments: any[] = [];
  clonedAppointments: any[] = [];
  @ViewChild('dt')
  dt!: Table;
  selectedDate: any;
  statuses = [
    { label: 'Επιβεβαιωμένο', value: 1 },
    { label: 'Απορρίφθηκε', value: 2 },
    { label: 'Εκκρεμεί', value: 3 },
    { label: 'Ακυρωμένο', value: 4 },
    { label: 'Ολοκληρωμένο', value: 5 },
  ];
  selectedAppointmentStatus: any;
  showAppointment: boolean = false;
  appointmentData!: Appointment;
  // activeIndex: any = 0;
  // activeIndex: number = 1; // Set to 1 to make "Ραντεβού" the default tab
  notes: string = 'Επείγον';
  currentDate = new Date();
  status: any = [
    { label: AppointmentStatus.APPROVED, value: 1 },
    { label: 'Απορρίφθηκε', value: 2 },
    { label: AppointmentStatus.PENDING, value: 3 },
    { label: AppointmentStatus.REJECTED, value: 4 },
    { label: AppointmentStatus.COMPLETED, value: 5 },
  ];
  selectedStatus: any;

  items: MenuItem[] = [];
  selectedMenuItem: string = 'appointments';
  // menuItems: MenuItem[] = [];
  selectedAppointment: any = null;
  @ViewChild('menu') menu!: Menu;

  constructor(
    private appointmentService: AppointmentsService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.fetchData();
    this.getCalendarNavigationItems();
    this.availableTimes = this.getTimesAsObjects();
    this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment == 'pendingappointments') {
        this.selectedMenuItem = 'pendingappointments';
      }
    });
  }

  onMenuButtonClick(event: MouseEvent, appointment: Appointment) {
    event.preventDefault();
    this.selectedAppointment = appointment;
    this.cd.detectChanges();
    this.ngZone.runOutsideAngular(() => {
      this.menu.toggle(event);
    });
  }

  menuItems(appointment: any): MenuItem[] {
    return [
      {
        label: 'Επεξεργασία',
        icon: 'pi pi-fw pi-pencil',
        command: () => this.editAppointment(appointment),
      },
      {
        label: 'Απόρριψη',
        icon: 'pi pi-fw pi-times',
        command: () => this.reject(appointment),
      },
    ];
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  fetchData() {
    const doctorSubscription$ = this.authService.currentUserSubject
      .pipe(
        switchMap((doctor) => {
          if (doctor) {
            return forkJoin([this.fetchAppointments(doctor.data.userId)]);
          }
          return [];
        })
      )
      .subscribe({
        next: ([appointmentResult]) =>
          this.handleAppointmentResult(appointmentResult),
        error: (err) => console.error('Error fetching appointments:', err),
      });

    this.subscriptions.add(doctorSubscription$);
  }

  private fetchAppointments(userId: number) {
    return this.appointmentService.getAppointments(userId);
  }

  private handleAppointmentResult(appointmentResult: any): void {
    if (!appointmentResult) return;

    this.patient_history = appointmentResult.data;
    this.appointments = this.patient_history;
    this.formatAppointments(appointmentResult.data);
    this.clonedAppointments = [...this.appointments];

    this.handleAppointments();
    this.getCalendarOptions(this.appointments);
    console.log(this.appointments);
  }

  private formatAppointments(data: any) {
    data.map((appointment: any) => {
      this.getAppointmentStatus(appointment);
    });
  }
  getAppointmentStatus(appointment: any) {
    let appDate = new Date(appointment.appointmentDate);
    if (
      this.currentDate > appDate &&
      (appointment.appointmentStatus.appointmentStatusId == 3 ||
        appointment.appointmentStatus.appointmentStatusId == 1)
    ) {
      appointment.appointmentStatus = {
        appointmentStatusId: 2,
        name: 'Ακυρωμένο',
      };
    }
    return appointment.appointmentStatus;
  }
  handleAppointments() {
    this.appointments.sort((a: any, b: any) => {
      const dateA = new Date(a.appointmentDate).getTime();
      const dateB = new Date(b.appointmentDate).getTime();
      return dateB - dateA;
    });
    this.pendingAppointments = this.appointments.filter((item: any) => {
      return (
        item.appointmentStatus.appointmentStatusId == 3 ||
        item.appointmentStatus.name == 'Εκκρεμεί'
      );
    });
    this.confirmed_patient_history = this.appointments.filter((item: any) => {
      return (
        item.appointmentStatus.appointmentStatusId == 5 ||
        item.appointmentStatus.name == 'Ολοκληρωμένο'
      );
    });
  }
  getCalendarOptions(data: any) {
    this.calendarOptions = {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin], // Ensure plugins are included
      height: 720,
      initialView: 'dayGridMonth',
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay',
      },
      editable: true,
      selectable: false,
      selectMirror: true,
      dayMaxEvents: true,
      eventClick: (e: MouseEvent) => this.onEventClick(e),
      select: (e: MouseEvent) => this.onDateSelect(e),

      events: data.map((appointment: any) => {
        // Generate a random time for the appointment
        // Parse the appointment start time
        const startDateTime = new Date(
          `${appointment.appointmentDate}T${appointment.appointmentTime}`
        );

        // Clone the date object to create the end time, and add one hour
        const endDateTime = new Date(startDateTime);
        endDateTime.setHours(startDateTime.getHours() + 1);
        let color;

        // Determine color and status based on appointment status
        switch (appointment.appointmentStatus.appointmentStatusId) {
          case 1:
            color = '#17a2b8'; // Yellow for confirmed
            status = 'info';
            break;
          case 2:
            color = '#dc3545'; // Red for rejected
            status = 'danger';
            break;
          case 3:
            color = '#ffc107'; // Blue for pending
            status = 'warning';
            break;
          case 4:
            color = '#dc3545'; // Grey for canceled
            status = 'danger';
            break;
          case 5:
            color = '#28a745'; // Green for completed
            status = 'success';
            break;
          default:
            color = '#dc3545'; // Red for any other unrecognized status
            status = 'danger';
            break;
        }

        // Return event object with computed properties
        return {
          title: appointment.fullName,
          start: startDateTime.toISOString(), // or keep as `${appointment.appointmentDate}T${appointment.appointmentTime}`
          end: endDateTime.toISOString(),
          description: appointment.reasonOfVisit,
          color: color,
        };
      }),
    };
  }

  getFilteredPatientHistory(fullName: string) {
    if (!fullName) {
      return [];
    }
    return this.confirmed_patient_history.filter(
      (appointment) => appointment.fullName === fullName
    );
  }

  onRowEditInit(appointment: any) {
    this.clonedAppointments[appointment.appointmentId] = { ...appointment };
  }

  onRowEditSave(appointment: any) {
    if (appointment.appointmentId > 0) {
      delete this.clonedAppointments[appointment.appointmentId];

      let appointmentStatus = {
        appointmentStatusId: appointment.appointmentStatus.appointmentStatusId,
      };
      const approveAppointmentService$ = this.appointmentService
        .updateAppointmentStatusById(
          appointment.appointmentId,
          appointmentStatus
        )
        .subscribe({
          next: (res) => {
            console.log('change res', res);
            this.statuses.map((x) => {
              if (x.value == appointmentStatus.appointmentStatusId) {
                appointment.appointmentStatus.name = x.label;
              }
            });
            // this.getCalendarOptions(this.appointments);

            if (appointmentStatus.appointmentStatusId == '5') {
              this.router.navigate(['patient-details', appointment.userId]);
            }
            this.successVisible = true;
          },
          error: (error: any) => {
            this.errorVisible = true;
            console.error('Error approving appointment:', error);
          },
          complete: () => {},
        });
      this.subscriptions.add(approveAppointmentService$);
    }
  }

  onRowEditCancel(appointment: any, index: number) {
    this.appointments[index] =
      this.clonedAppointments[appointment.appointmentId];
    delete this.clonedAppointments[appointment.appointmentId];
  }

  reject(appointment: any) {
    let appointmentStatus = {
      appointmentStatusId: 2,
    };
    const appointmentService$ = this.appointmentService
      .updateAppointmentStatusById(appointment.appointmentId, appointmentStatus)
      .subscribe({
        next: (res) => {
          appointment.appointmentStatus.name = 'Απορρίφθηκε';
          this.pendingAppointments = this.pendingAppointments.filter(
            (app) => app.appointmentId != appointment.appointmentId
          );
          this.successDeleteVisible = true;
        },
        error: (error: any) => {
          this.errorVisible = true;
          console.error('Error approving appointment:', error);
        },
        complete: () => {},
      });
    this.subscriptions.add(appointmentService$);
  }

  approve(appointment: any) {
    let appointmentStatus = {
      appointmentStatusId: 1,
    };
    this.subscriptions.add(
      this.appointmentService
        .updateAppointmentStatusById(
          appointment.appointmentId,
          appointmentStatus
        )
        .subscribe({
          next: (res) => {
            appointment.appointmentStatus.name = 'Επιβεβαιωμένο';

            this.pendingAppointments = this.pendingAppointments.filter(
              (app) => app.appointmentId != appointment.appointmentId
            );
            this.successVisible = true;
          },
          error: (error: any) => {
            this.errorVisible = true;
            console.error('Error approving appointment:', error);
          },
          complete: () => {},
        })
    );
  }

  showAppointmentDetails(appointment: any) {
    this.showAppointment = true;
    this.appointmentData = appointment;
  }
  editAppointment(appointment: any) {
    this.editAppointmentDialog = true;
    this.appointmentData = appointment;
    this.cd.detectChanges();
    console.log(this.appointmentData);
  }

  getSelectedTime(event: any) {
    const time = event;
    console.log(time.target.value);
  }

  handleSave() {}
  onEditClick() {}
  onEventClick(e: any) {
    this.clickedEvent = e.event;
    let plainEvent = e.event.toPlainObject({
      collapseExtendedProps: true,
      collapseColor: true,
    });
    this.view = 'display';
    this.showDialog = true;

    this.changedEvent = { ...plainEvent, ...this.clickedEvent };
    this.changedEvent.start = this.clickedEvent.start;
    this.changedEvent.end = this.clickedEvent.end
      ? this.clickedEvent.end
      : this.clickedEvent.start;
  }

  onDateSelect(e: any) {
    this.view = 'new';
    this.showDialog = true;
    this.changedEvent = {
      ...e,
      title: null,
      description: null,
      location: null,
      backgroundColor: null,
      borderColor: null,
      textColor: null,
      tag: { color: null, name: null },
    };
  }

  filter(table: Table, event: Date) {
    console.log(
      event.toLocaleDateString('en-GB'),
      ' ',
      this.appointments[0].appointmentDate
    );
    // console.log((event.target as HTMLInputElement).value)
    this.dt.filterGlobal(event.toLocaleDateString('en-GB'), 'contains');
  }

  filterByDate(dt2: Table) {
    if (this.selectedDate) {
      const formattedDate = this.formatDate(this.selectedDate);
      dt2.filter(formattedDate, 'appointmentDate', 'equals');
    }
  }

  // Helper function to format the date to match the 'appointmentDate' format
  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  // Method to reset the filter and show all rows
  resetFilter(dt2: Table) {
    this.selectedDate = null;
    dt2.clear();
  }

  // Method to handle date change and reset the filter when cleared
  onDateChange(event: any, dt2: Table) {
    if (event === null || event === '') {
      this.resetFilter(dt2);
    } else {
      this.filterByDate(dt2);
    }
  }
  onStatusChange(event: any, dt2: Table) {
    console.log(event);
    dt2.filter(event.value, 'appointmentStatus.appointmentStatusId', 'equals');
  }

  getSeverity(status: any) {
    switch (status) {
      case 'Επιβεβαιωμένο':
        return 'info';
      case 'Απορρίφθηκε':
        return 'danger';
      case 'Εκκρεμεί':
        return 'warning';
      case 'Ακυρωμένο':
        return 'danger';
      case 'Ολοκληρωμένο':
        return 'success';
      case 'Απόρριψη':
        return 'danger';

      case 1:
        return 'info';
      case 2:
        return 'danger';
      case 3:
        return 'warning';
      case 4:
        return 'danger';
      case 5:
        return 'success';
      default:
        return undefined;
    }
  }

  getTimesAsObjects(): { name: string; code: string }[] {
    const times: { name: string; code: string }[] = [];
    const startHour = 9; // 9 AM
    const endHour = 21; // 9 PM (21:00)

    for (let hour = startHour; hour <= endHour; hour++) {
      const formattedTime = `${hour.toString().padStart(2, '0')}:00`;
      const timeCode = hour.toString().padStart(2, '0'); // Using the hour as code

      times.push({ name: formattedTime, code: timeCode });
    }
    console.log(times);

    return times;
  }

  selectMenuItem(item: any): void {
    this.selectedMenuItem = item;
    // Update the styleClass for each menu item based on selection
    this.items = this.items.map((menuItem) => ({
      ...menuItem,
      styleClass:
        menuItem.label === this.getLabelByItemKey(item)
          ? 'active-menu-item'
          : '',
    }));
  }

  // Helper method to get the label based on the selected key
  getLabelByItemKey(key: string): string {
    const map: { [key: string]: string } = {
      calendar: 'Ημερολόγιο',
      appointments: 'Ραντεβού',
      pendingappointments: 'Ραντεβού σε εκκρεμότητα',
    };
    return map[key] || '';
  }

  getCalendarNavigationItems() {
    this.items = [
      {
        label: 'Ημερολόγιο',
        icon: '',
        command: () => this.selectMenuItem('calendar'),
        styleClass:
          this.selectedMenuItem === 'calendar' ? 'active-menu-item' : '',
      },
      {
        label: 'Ραντεβού',
        icon: '',
        command: () => this.selectMenuItem('appointments'),
        styleClass:
          this.selectedMenuItem === 'appointments' ? 'active-menu-item' : '',
      },
      {
        label: 'Εκκρεμή Ραντεβού',
        icon: '',
        command: () => this.selectMenuItem('pendingappointments'),
        styleClass:
          this.selectedMenuItem === 'pendingappointments'
            ? 'active-menu-item'
            : '',
      },
    ];
  }
}
