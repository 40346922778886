@if(dataLoaded){
<div class="grid row-gap-2">
  <div class="col-12 grid">
    <div class="xl:col-3 sm:col-6 col-12">
      <div
        class="card flex flex-column justify-content-between p-0 text-center lg:gap-3 gap-1 cursor-pointer h-full"
        (click)="requestAccessDialog = true"
      >
        <span class="font-bolder text-xl mt-3">Φάκελος Υγείας</span>
        <i class="pi pi-sign-in lg:text-6xl text-2xl font-bold"></i>
        <div
          class="pButton bg-primary-700 text-white font-bold border-round-bottom-3xl p-1 text-xl cursor-pointer"
        >
          Αίτημα Πρόσβασης
        </div>
      </div>
    </div>

    <div class="xl:col-3 sm:col-6 col-12">
      <div
        class="card flex flex-column justify-content-between p-0 text-center lg:gap-3 gap-1 cursor-pointer"
        (click)="showAddPrescription()"
      >
        <span class="font-bolder text-xl mt-3">Συνταγογραφήσεις</span>
        <span class="font-bold lg:text-6xl text-2xl mt-3 text-primary">{{
          30 | number
        }}</span>
        <div
          class="pButton flex justify-content-center align-items-center gap-3 bg-primary-700 border-round-bottom-3xl p-1 cursor-pointer"
        >
          <span class="bg-primary-700 border-none text-white font-bold text-xl">
            Συνταγογράφηση
          </span>
          <i class="pi pi-plus text-white font-bold text-xl"></i>
        </div>
      </div>
    </div>
    <div class="xl:col-3 sm:col-6 col-12">
      <div
        class="card flex flex-column justify-content-between p-0 text-center lg:gap-3 gap-1 cursor-pointer"
        (click)="showAddReference()"
      >
        <span class="font-bolder text-xl mt-3">Εξετάσεις</span>
        <span class="font-bold lg:text-6xl text-2xl mt-3 text-primary">{{
          5 | number
        }}</span>
        <div
          class="flex justify-content-center align-items-center gap-3 bg-primary-700 border-round-bottom-3xl p-1"
        >
          <span class="bg-primary-700 border-none text-white font-bold text-xl">
            Παραπομπή
          </span>
          <i class="pi pi-plus text-white font-bold text-xl"></i>
        </div>
      </div>
    </div>
    <div class="xl:col-3 sm:col-6 col-12">
      <div
        class="card flex flex-column justify-content-between p-0 text-center lg:gap-3 gap-1 cursor-pointer"
        (click)="showHospitalizationDialog = true"
      >
        <span class="font-bolder text-xl mt-3">Νοσηλεία</span>
        <span class="font-bold lg:text-6xl text-2xl mt-3 text-primary">{{
          2 | number
        }}</span>
        <div
          class="flex justify-content-center align-items-center gap-3 bg-primary-700 border-round-bottom-3xl p-1"
        >
          <span class="bg-primary-700 border-none text-white font-bold text-xl">
            Παραπομπή
          </span>
          <i class="pi pi-plus text-white font-bold text-xl"></i>
        </div>
      </div>
    </div>
  </div>
  <p-dialog
    header="Αίτηση Πρόσβασης"
    [(visible)]="requestAccessDialog"
    [modal]="true"
    [style]="{ width: '30rem' }"
    [resizable]="false"
    [draggable]="false"
    showEffect="fade"
  >
    <div class="flex flex-column gap-3">
      <span
        >Είστε σίγουροι ότι θέλετε να ζητήσετε πρόσβαση στον φάκελο υγείας του
        ασθενή;</span
      >
    </div>
    <ng-template pTemplate="footer">
      <button
        pButton
        (click)="requestAccessDialog = false"
        label="Όχι"
        class="p-button-outlined"
      ></button>
      <button
        [disabled]="requestAccessDisabled"
        pButton
        label="Ναι"
        class="p-button-outlined"
      ></button>
    </ng-template>
  </p-dialog>
  <!-- Prescription Modal -->
  <p-dialog
    header="Header"
    [(visible)]="showPrescription"
    [modal]="true"
    [style]="{ width: '50rem' }"
  >
    <ng-template pTemplate="header">
      <div class="flex align-items-center justify-content-center gap-2">
        <span class="font-bold"></span>
      </div>
    </ng-template>
    <div class="flex align-items-center justify-content-center gap-2">
      <span class="font-bold text-xl">Συνταγογράφηση</span>
    </div>
    <div class="flex flex-column gap-2 mb-3">
      <label for="patient" class="font-semibold w-6rem"> Ασθενής </label>
      <input pInputText id="patient" class="flex-auto" autocomplete="off" />
    </div>
    <div class="flex flex-column gap-2 mb-3">
      <label for="doctor" class="font-semibold w-6rem"> Ιατρός </label>
      <input pInputText id="doctor" class="flex-auto" autocomplete="off" />
    </div>
    <div class="flex justify-content-between">
      <div class="flex flex-column gap-2 mb-3">
        <label for="prescriptionDate" class="font-semibold w-6rem">
          Ημ.Συνταγής
        </label>
        <input
          pInputText
          id="prescriptionDate"
          class="flex-auto"
          autocomplete="off"
        />
      </div>
      <div class="flex flex-column gap-2 mb-3">
        <label for="prescriptionExpirationDate" class="font-semibold">
          Ημ.Λήξης Συνταγής
        </label>
        <input
          pInputText
          id="prescriptionExpirationDate"
          disabled
          placeholder="Σημείωση: 2 μήνες μέτα θα λήγει"
          class="flex-auto"
          autocomplete="off"
        />
      </div>
    </div>
    <div class="flex flex-column gap-4 mb-3">
      <label for="prescriptionDate" class="font-semibold">
        Σύνθεση Συνταγής
      </label>
    </div>
    <div id="medicines" class="flex flex-column gap-3 mb-3">
      <div class="flex gap-5">
        <div class="flex flex-column gap-2">
          <label for="prescriptionExpirationDate" class="font-semibold">
            Φάρμακο
          </label>
          <input
            pInputText
            id="prescriptionExpirationDate"
            class="flex-auto"
            autocomplete="off"
          />
        </div>
        <div class="flex flex-column gap-2">
          <label for="quantity" class="font-semibold"> Ποσότητα </label>
          <input
            pInputText
            id="prescriptionExpirationDate"
            class="flex-auto"
            autocomplete="off"
          />
        </div>
        <div
          pButton
          class="pi pi-plus align-self-end"
          (click)="addMedicine()"
        ></div>
      </div>
    </div>
    <div class="w-full flex flex-column gap-3">
      <label for="comments" class="font-semibold"> Σημειώσεις </label>
      <textarea
        rows="5"
        cols="30"
        placeholder="Your Message"
        pInputTextarea
      ></textarea>
    </div>
    <ng-template pTemplate="footer">
      <p-button
        label="Ακύρωση"
        [text]="true"
        severity="secondary"
        (onClick)="showPrescription = false"
      />
      <p-button
        label="Υποβολή"
        [outlined]="true"
        severity="secondary"
        (onClick)="showPrescription = false"
      />
    </ng-template>
  </p-dialog>
  <!-- Reference Modal -->
  <p-dialog
    header="Header"
    [(visible)]="showReference"
    [modal]="true"
    [style]="{ width: '50rem' }"
  >
    <ng-template pTemplate="header">
      <div class="flex align-items-center justify-content-center gap-2">
        <span class="font-bold"></span>
      </div>
    </ng-template>
    <div class="flex align-items-center justify-content-center gap-2 m-3">
      <span class="font-bold text-xl">Παραπομπή για εξετάσεις</span>
    </div>
    <div class="flex flex-column gap-2 mb-3">
      <label for="patient" class="font-semibold w-6rem"> Ασθενής </label>
      <input
        pInputText
        id="patient"
        class="flex-auto"
        autocomplete="off"
        [value]="insured?.firstName + ' ' + insured?.lastName"
        disabled
      />
    </div>
    <div class="flex flex-column gap-2 mb-3">
      <label for="doctor" class="font-semibold w-6rem"> Ιατρός </label>
      <input
        pInputText
        id="doctor"
        class="flex-auto"
        autocomplete="off"
        [value]="doctor.data.fullName"
        disabled
      />
    </div>
    <div class="flex flex-column gap-2 mb-3" (keydown.enter)="searchIcd10()">
      <label for="prescriptionExpirationDate" class="font-semibold">
        Διάγνωση
      </label>
      <div class="flex flex-row justify-content-between gap-5">
        <input
          pInputText
          class="flex-auto"
          autocomplete="off"
          id="icd10"
          [(ngModel)]="icd10"
          placeholder="Εισάγετε όνομα ή κωδικό ICD10"
        />
        <p-button icon="pi pi-search" (onClick)="searchIcd10()" />
      </div>
      @if(selectedIcd10[0]?.name){
      <p-chip
        styleClass="pl-0 pr-3"
        [removable]="true"
        (onRemove)="selectedIcd10 = ''"
      >
        <span
          class="bg-primary border-circle w-2rem h-2rem flex align-items-center justify-content-center"
        >
          <i class="pi pi-file"></i>
        </span>
        <span class="ml-2 font-medium">
          {{ selectedIcd10[0].name }}
        </span>
      </p-chip>
      }
    </div>
    <div class="flex flex-column gap-2 mb-3">
      <label for="prescriptionExpirationDate" class="font-semibold">
        Επιλογή Εξέτασης
      </label>
      <p-multiSelect
        [style]="{ width: '100%' }"
        [options]="exams"
        [(ngModel)]="selectedExams"
        placeholder="Επιλέξτε Εξετάσεις"
        optionLabel="name"
        scrollHeight="250px"
        [showClear]="true"
        display="chip"
        appendTo="body"
      >
        <ng-template let-medicine pTemplate="item">
          <span class="ml-2">{{ medicine.name }}</span>
        </ng-template>
      </p-multiSelect>
    </div>
    <div class="flex flex-column gap-2 mb-3">
      <label for="prescriptionExpirationDate" class="font-semibold">
        Ημ.Λήξης Έξετασης
      </label>
      <input
        pInputText
        id="prescriptionExpirationDate"
        disabled
        placeholder="Σημείωση: 2 μήνες μέτα θα λήγει"
        class="flex-auto"
        autocomplete="off"
      />
    </div>

    <div class="w-full flex flex-column gap-3">
      <label for="comments" class="font-semibold"> Σημειώσεις </label>
      <textarea
        rows="5"
        cols="30"
        [(ngModel)]="textAreaValue"
        pInputTextarea
      ></textarea>
    </div>
    @if(modalMessage){
    <div class="w-full flex flex-column gap-3 mt-3">
      @for(message of modalMessage; track message){
      <span class="text-red-400 text-lg font-medium">{{ message }}</span>
      }
    </div>
    }
    <ng-template pTemplate="footer">
      <button pButton severity="secondary" (click)="showReference = false">
        Ακύρωση
      </button>
      <button pButton severity="secondary" (click)="addReference()">
        Υποβολή
      </button>
    </ng-template>
  </p-dialog>

  <p-dialog
    header="Header"
    [(visible)]="showHospitalizationDialog"
    [modal]="true"
    [style]="{ width: '50rem' }"
  >
    <ng-template pTemplate="header">
      <div class="flex align-items-center justify-content-center gap-2">
        <span class="font-bold"></span>
      </div>
    </ng-template>
    <div class="flex align-items-center justify-content-center gap-2 m-3">
      <span class="font-bold text-xl">Παραπομπή για νοσηλεία</span>
    </div>
    <div class="flex flex-column gap-2 mb-3">
      <label for="patient" class="font-semibold w-6rem"> Ασθενής </label>
      <input
        pInputText
        id="patient"
        class="flex-auto"
        autocomplete="off"
        [value]="insured?.firstName + ' ' + insured?.lastName"
        disabled
      />
    </div>
    <div class="flex flex-column gap-2 mb-3">
      <label for="doctor" class="font-semibold w-6rem"> Ιατρός </label>
      <input
        pInputText
        id="doctor"
        class="flex-auto"
        autocomplete="off"
        [value]="doctor.data.fullName"
        disabled
      />
    </div>
    <div class="flex flex-column gap-2 mb-3" (keydown.enter)="searchIcd10()">
      <label for="prescriptionExpirationDate" class="font-semibold">
        Διάγνωση
      </label>
      <div class="flex flex-row justify-content-between gap-5">
        <input
          pInputText
          class="flex-auto"
          autocomplete="off"
          id="icd10"
          [(ngModel)]="icd10"
          placeholder="Εισάγετε όνομα ή κωδικό ICD10"
        />
        <p-button icon="pi pi-search" (onClick)="searchIcd10()" />
      </div>
      @if(selectedIcd10[0]?.name){
      <p-chip
        styleClass="pl-0 pr-3"
        [removable]="true"
        (onRemove)="selectedIcd10 = ''"
      >
        <span
          class="bg-primary border-circle w-2rem h-2rem flex align-items-center justify-content-center"
        >
          <i class="pi pi-file"></i>
        </span>
        <span class="ml-2 font-medium">
          {{ selectedIcd10[0].name }}
        </span>
      </p-chip>
      }
    </div>

    <div class="w-full flex flex-column gap-3">
      <label for="comments" class="font-semibold"> Γνωμάτευση </label>
      <textarea
        rows="5"
        cols="30"
        [(ngModel)]="textAreaValue"
        pInputTextarea
      ></textarea>
    </div>
    @if(modalMessage){
    <div class="w-full flex flex-column gap-3 mt-3">
      @for(message of modalMessage; track message){
      <span class="text-red-400 text-lg font-medium">{{ message }}</span>
      }
    </div>
    }
    <ng-template pTemplate="footer">
      <p-button
        label="Ακύρωση"
        [text]="true"
        severity="secondary"
        (onClick)="showHospitalizationDialog = false"
      />
      <p-button
        label="Υποβολή"
        [outlined]="true"
        [disabled]="false"
        severity="secondary"
        (click)="addHospitalization($event)"
      />
    </ng-template>
  </p-dialog>
  @if(insured){
  <div class="col-12">
    <div class="card">
      <p-tabView class="mt-3" [scrollable]="true">
        <p-tabPanel header="Προφίλ">
          <h6 class="my-3 text-primary">Προσωπικά Στοιχεία​</h6>
          <div class="mt-3 p-fluid">
            <div class="p-formgrid grid">
              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="name2">Όνομα</label>
                <input
                  pInputText
                  id="name2"
                  type="text"
                  disabled
                  [value]="insured.firstName"
                />
              </div>
              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="email2">Επώνυμο</label>
                <input
                  pInputText
                  id="email2"
                  type="text"
                  disabled
                  [value]="insured.lastName"
                />
              </div>
              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="email2">Ημ.Γεννησης</label>
                <input
                  pInputText
                  id="email2"
                  type="text"
                  disabled
                  [value]="insured.dateOfBirth | date : 'dd/MM/yyyy'"
                />
              </div>
              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="name2">Φύλο</label>
                <input
                  pInputText
                  id="name2"
                  type="text"
                  disabled
                  [value]="insured.gender"
                />
              </div>
              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="email2">Εθνικότητα</label>
                <input
                  pInputText
                  id="email2"
                  type="text"
                  disabled
                  [value]="insured.nationality"
                />
              </div>
              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="email2"
                  >Οικογενειακή Κατάσταση</label
                >
                <input
                  pInputText
                  id="email2"
                  type="text"
                  disabled
                  [value]="insured.maritalStatus"
                />
              </div>
            </div>
          </div>
          <h6 class="my-3 text-primary">Αναγνωριστικά​</h6>
          <div class="mt-3 p-fluid">
            <div class="p-formgrid grid">
              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="name2">A.M.K.A</label>
                <input
                  pInputText
                  id="name2"
                  type="text"
                  disabled
                  [value]="insured.socialSecurityNumber"
                />
              </div>
              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="email2">Α.Φ.Μ</label>
                <input
                  pInputText
                  id="email2"
                  type="text"
                  disabled
                  [value]="insured.taxIdentificationNumber"
                />
              </div>
              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="email2">Α.Δ.Τ</label>
                <input
                  pInputText
                  id="email2"
                  type="text"
                  disabled
                  [value]="insured.identityCardNumber"
                />
              </div>
            </div>
          </div>
          <h6 class="my-3 text-primary">Στοιχεία​ Επικοινωνίας</h6>
          <div class="mt-3 p-fluid">
            <div class="p-formgrid grid">
              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="name2">Κινητό 1</label>
                <input
                  pInputText
                  id="name2"
                  type="text"
                  disabled
                  [value]="insured.mobile"
                />
              </div>
              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="name2">Σταθερό</label>
                <input
                  pInputText
                  id="name2"
                  type="text"
                  disabled
                  [value]="insured.telephone"
                />
              </div>

              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="name2">Email</label>
                <input
                  pInputText
                  id="name2"
                  type="text"
                  disabled
                  [value]="insured.email"
                />
              </div>
              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="name2">Δήμος</label>
                <input
                  pInputText
                  id="name2"
                  type="text"
                  disabled
                  [value]="insured.municipality"
                />
              </div>

              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="name2">Οδός, Αριθμός</label>
                <input
                  pInputText
                  id="name2"
                  type="text"
                  disabled
                  [value]="insured.address"
                />
              </div>
              <div class="field lg:col-4 md:col-6 col-12">
                <label class="font-bold" htmlFor="name2">Τ.Κ.</label>
                <input
                  pInputText
                  id="name2"
                  type="text"
                  disabled
                  [value]="insured.zipCode"
                />
              </div>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Ιστορικό">
          <div
            class="flex md:flex-row flex-column md:justify-content-between align-items-center my-2"
          >
            <h6 class="my-3 text-primary">Ιστορικό Νοσημάτων​</h6>
            <!-- <button
              pButton
              pRipple
              label="Προσθήκη Διάγνωσης"
              class="p-button-success"
            ></button> -->
          </div>
          <p-table [value]="diagnoses">
            <ng-template pTemplate="header">
              <tr>
                <th>ICD10</th>
                <th>Διάγνωση</th>
                <th>Σχόλιο</th>
                <th>Συμβάν</th>
                <th>Έναρξη</th>
                <th>Έκβαση</th>
                <th>Λήξη</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-diagnosis>
              <tr>
                <td>{{ diagnosis.icd10 }}</td>
                <td>{{ diagnosis.diagnosis }}</td>
                <td>{{ diagnosis.comment }}</td>
                <td>{{ diagnosis.event }}</td>
                <td>{{ diagnosis.startDate | date : "dd/MM/yyyy" }}</td>
                <td>{{ diagnosis.outcome }}</td>
                <td>{{ diagnosis.endDate | date : "dd/MM/yyyy" }}</td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>

        <p-tabPanel header="Επισκέψεις">
          <div
            class="flex md:flex-row flex-column md:justify-content-between align-items-center my-2"
          >
            <h6 class="my-3 text-primary">Επισκέψεις</h6>
            <!-- <button
              pButton
              pRipple
              label="Προσθήκη Έπισκεψης"
              class="p-button-success"
            ></button> -->
          </div>

          <p-table [value]="records">
            <ng-template pTemplate="header">
              <tr>
                <th>Ημερομηνία</th>
                <th>Τίτλος</th>
                <th>Κατηγορία</th>
                <th>Αιτιολογία</th>
                <th>Σημειώσεις</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-record>
              <tr>
                <td>{{ record.date | date : "dd/MM/yyyy" }}</td>
                <td>{{ record.title }}</td>
                <td>{{ record.category }}</td>
                <td>{{ record.reason }}</td>
                <td>{{ record.notes }}</td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>

        <p-tabPanel header="Εργαστηριακές Εξετάσεις">
          <div
            class="flex md:flex-row flex-column md:justify-content-between align-items-center my-2"
          >
            <h6 class="my-3 text-primary">Εργαστηριακές Εξετάσεις</h6>
            <!-- <button
              pButton
              pRipple
              label="Προσθήκη Εξέτασης"
              class="p-button-success"
            ></button> -->
          </div>
          <p-table [value]="examinations">
            <ng-template pTemplate="header">
              <tr>
                <th>Ημερομηνία</th>
                <th>Κατηγορία Εξέτασης</th>
                <th>Εξέταση</th>
                <th>Αποτέλεσμα</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-exam>
              <tr>
                <td>{{ exam.date | date : "dd/MM/yyyy" }}</td>
                <td>{{ exam.category }}</td>
                <td>{{ exam.examination }}</td>
                <td>{{ exam.result }}</td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>

        <p-tabPanel header="Κλινικές Εξετάσεις">
          <div
            class="flex md:flex-row flex-column md:justify-content-between align-items-center my-2"
          >
            <h6 class="my-3 text-primary">Κλινικές εξετάσεις</h6>
            <!-- <button
              pButton
              pRipple
              label="Προσθήκη Εξέτασης"
              class="p-button-success"
            ></button> -->
          </div>
          @if(clinicalExams){
          <p-table [value]="clinicalExams">
            <ng-template pTemplate="header">
              <tr>
                <th>Ημερομηνία</th>
                <th>Εξέταση</th>
                <th>Σημειώσεις</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-exam>
              <tr>
                <td>{{ exam.examDate | date : "dd/MM/yyyy" }}</td>
                <td>{{ exam.type }}</td>
                <td>{{ exam.notes }}</td>
              </tr>
            </ng-template>
          </p-table>
          }
        </p-tabPanel>

        <p-tabPanel header="Αρχεία">
          <p-table
            responsiveLayout="scroll"
            #dt
            [value]="files"
            [rows]="8"
            [paginator]="true"
          >
            <ng-template pTemplate="header">
              <tr>
                <th
                  pSortableColumn="name"
                  style="min-width: 12rem"
                  class="white-space-nowrap"
                >
                  Όνομα Αρχείου <p-sortIcon field="name"></p-sortIcon>
                </th>
                <th
                  pSortableColumn="date"
                  style="min-width: 12rem"
                  class="white-space-nowrap"
                >
                  Ημερομηνία<p-sortIcon field="date"></p-sortIcon>
                </th>
                <th
                  pSortableColumn="fileSize"
                  style="min-width: 12rem"
                  class="white-space-nowrap"
                >
                  Μέγεθος Αρχείου<p-sortIcon field="name"></p-sortIcon>
                </th>
                <th style="width: 10rem"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-file>
              <tr>
                <td>
                  <div class="flex align-items-center">
                    <i [class]="'text-xl text-primary mr-2 ' + file.icon"></i>
                    <span>{{ file.fileName }}</span>
                  </div>
                </td>
                <td>
                  <span>{{ file.dateOfUpload | date }}</span>
                </td>
                <td>
                  <span>{{ file.fileSize }}</span>
                </td>
                <td class="text-center">
                  <button
                    pButton
                    pRipple
                    icon="pi pi-times"
                    class="p-button-danger p-button-text p-button-rounded mr-2"
                  ></button>
                  <button
                    pButton
                    pRipple
                    icon="pi pi-search"
                    class="p-button-text p-button-rounded"
                  ></button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>

  <p-dialog
    header="Ευχαριστούμε!"
    [(visible)]="successVisible"
    [modal]="true"
    [style]="{ width: '30rem' }"
    [resizable]="false"
    [draggable]="false"
    showEffect="fade"
  >
    <p class="line-height-3 m-0">
      {{ modalMessage.APPOINTMENT_SUCCESS }}
    </p>
    <ng-template pTemplate="footer">
      <button
        pButton
        (click)="successVisible = false"
        label="Κλείσιμο"
        class="p-button-outlined"
      ></button>
    </ng-template>
  </p-dialog>
  <p-dialog
    header="Σφάλμα"
    [(visible)]="errorVisible"
    [modal]="true"
    [style]="{ width: '30rem' }"
    [resizable]="false"
    [draggable]="false"
    showEffect="fade"
  >
    <p class="line-height-3 m-0">
      {{ modalMessage.APPOINTMENT_ERROR }}
    </p>
    <ng-template pTemplate="footer">
      <button
        pButton
        (click)="errorVisible = false"
        label="Κλείσιμο"
        class="p-button-outlined"
      ></button>
    </ng-template>
  </p-dialog>
  }
</div>
} @else {
<div
  class="col-12 flex justify-content-center align-items-center h-100em w-100em"
>
  <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
</div>

}
