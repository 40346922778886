<p-table
  #dt2
  [value]="appointments"
  [paginator]="true"
  [rows]="10"
  dataKey="appointmentId"
  editMode="row"
  [tableStyle]="{ 'min-width': '75rem' }"
  [globalFilterFields]="['appointmentDate']"
  [rowsPerPageOptions]="[5, 10, 20]"
>
  <ng-template pTemplate="caption">
    <div class="flex justify-content-end gap-3">
      <p-dropdown
        [options]="status"
        [(ngModel)]="selectedStatus"
        (ngModelChange)="onStatusChange($event, dt2)"
        autoWidth="false"
        optionLabel="label"
        placeholder="Όλα"
        [style]="{ minWidth: '12rem' }"
      />
      <p-calendar
        [(ngModel)]="selectedDate"
        [showIcon]="true"
        dateFormat="dd/mm/yy"
        (ngModelChange)="onDateChange($event, dt2)"
        [showButtonBar]="true"
      ></p-calendar>
    </div>
  </ng-template>
  @if(tableHeaders && tableHeaders.length>0){
  <ng-template pTemplate="header">
    <tr>
      @for(header of tableHeaders;track header){
      <th>{{ header.header }}</th>
      }
      <th>Κατάσταση</th>
    </tr>
  </ng-template>
  }
  <ng-template
    pTemplate="body"
    let-appointment
    let-editing="editing"
    let-ri="rowIndex"
  >
    <tr [pEditableRow]="appointment">
      @for(col of tableHeaders; track col){ @if(col.field=='appointmentDate'){
      <td>{{ appointment[col.field] | date : "dd/MM/yyyy" }}</td>
      }@else if(col.field=='examStatusId'){
      <td>
        <p-tag
          [value]="getExamStringValue(appointment[col.field])"
          [severity]="getSeverity(appointment[col.field])"
        ></p-tag>
      </td>
      }@else if(col.field=="notes"){
      <td>{{ "Επείγον" }}</td>
      } @else{
      <td>{{ appointment[col.field] }}</td>
      } }
      <td>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-dropdown
              [options]="statuses"
              appendTo="body"
              optionLabel="label"
              optionValue="value"
              [(ngModel)]="appointment.appointmentStatus.appointmentStatusId"
              [style]="{ minWidth: '100%' }"
            />
          </ng-template>
          <ng-template pTemplate="output">
            <p-tag
              [value]="appointment.appointmentStatus.name"
              [severity]="getSeverity(appointment.appointmentStatus.name)"
            />
          </ng-template>
        </p-cellEditor>
      </td>
      <td>
        <div class="flex align-items-center justify-content-center gap-2">
          <button
            *ngIf="!editing"
            pButton
            pRipple
            [disabled]="
              appointment.appointmentStatus.appointmentStatusId == 3 ||
              appointment.appointmentStatus.appointmentStatusId == 1
                ? false
                : true
            "
            type="button"
            pInitEditableRow
            icon="pi pi-pencil"
            (click)="onRowEditInit(appointment)"
            class="p-button-rounded p-button-text"
          ></button>
          <button
            *ngIf="editing"
            pButton
            pRipple
            type="button"
            pSaveEditableRow
            icon="pi pi-check"
            (click)="onRowEditSave(appointment)"
            class="p-button-rounded p-button-text p-button-success mr-2"
          ></button>
          <button
            *ngIf="editing"
            pButton
            pRipple
            type="button"
            pCancelEditableRow
            icon="pi pi-times"
            (click)="onRowEditCancel(appointment, ri)"
            class="p-button-rounded p-button-text p-button-danger"
          ></button>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="8">Δεν βρέθηκαν ραντεβού</td>
    </tr>
  </ng-template>
</p-table>

<p-dialog
  header="Ευχαριστούμε!"
  [(visible)]="successVisible"
  [modal]="true"
  [style]="{ width: '30rem' }"
  [resizable]="false"
  [draggable]="false"
  showEffect="fade"
>
  <p class="line-height-3 m-0">
    {{ modalMessages.APPOINTMENT_SUCCESS }}
  </p>
  <ng-template pTemplate="footer">
    <button
      pButton
      (click)="successVisible = false"
      label="Κλείσιμο"
      class="p-button-outlined"
    ></button>
  </ng-template>
</p-dialog>
<p-dialog
  header="Σφάλμα"
  [(visible)]="errorVisible"
  [modal]="true"
  [style]="{ width: '30rem' }"
  [resizable]="false"
  [draggable]="false"
  showEffect="fade"
>
  <p class="line-height-3 m-0">
    {{ modalMessages.APPOINTMENT_ERROR }}
  </p>
  <ng-template pTemplate="footer">
    <button
      pButton
      (click)="errorVisible = false"
      label="Κλείσιμο"
      class="p-button-outlined"
    ></button>
  </ng-template>
</p-dialog>
