import { Component, Input, OnInit } from '@angular/core';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ImportsModule } from 'shared';

@Component({
  selector: 'app-dashboard-charts',
  standalone: true,
  imports: [ImportsModule, NgApexchartsModule],
  templateUrl: './dashboard-charts.component.html',
  styleUrl: './dashboard-charts.component.scss',
})
export class DashboardChartsComponent implements OnInit {
  chartOptions: any;
  @Input() chartCase: string = '';

  primaryColor = '#5297FF';
  grayColor = '#6c757d';

  ngOnInit() {
    this.getChartOptions(this.chartCase);
  }
  getChartOptions(chartCase: string) {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColorSecondary = documentStyle.getPropertyValue(
      '--text-color-secondary'
    );

    switch (chartCase) {
      case 'monthlyChart':
        this.chartOptions = {
          series: [
            {
              name: 'Εισοδήματα', // Label for the series
              data: [100, 60, 40], // Example data for Έσοδα, Εισπράξεις, and Εκκρεμότητες
            },
          ],
          chart: {
            type: 'bar',
            height: 350,
            fontFamily: 'Manrope, Arial, sans-serif',
            foreColor: textColorSecondary, // Assume textColorSecondary is defined in your component
          },
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            bar: {
              horizontal: true,
              distributed: true,
            },
          },
          xaxis: {
            categories: ['Έσοδα', 'Εισπράξεις', 'Εκκρεμότητες'],
          },
          title: {
            text: 'Μηνιαίο γράφημα', // Greek for "Monthly Chart (Revenue - Receipts - Outstanding)"
            align: 'center',
          },
          colors: [this.primaryColor, this.grayColor, '#5F8FE5'], // Assume primaryColor and grayColor are defined
        };
        break;
    }
  }
}
