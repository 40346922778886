import { AngularOpenlayersModule } from 'ng-openlayers';
import {
  AfterViewInit,
  Component,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import OSM from 'ol/source/OSM';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Icon } from 'ol/style';
import { fromLonLat } from 'ol/proj';
import { defaults as defaultControls } from 'ol/control';
import { FullScreen } from 'ol/control';
import { ScaleLine } from 'ol/control';
import { click } from 'ol/events/condition';
import Select from 'ol/interaction/Select';
import {
  Doctor,
  Address,
  DiagnosticCenter,
} from '../../interfaces/interface.module';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { DialogModule } from 'primeng/dialog';
import Overlay from 'ol/Overlay';
import { Cluster } from 'ol/source';
import { boundingExtent } from 'ol/extent';
import { Text, Fill, Stroke, Style, Circle as CircleStyle } from 'ol/style';

export interface LocationMarker {
  x: number;
  y: number;
  name: string;
  fullAddress?: string;
  data: DiagnosticCenter | Doctor;
  addresses?: Address[];
}

@Component({
  selector: 'lib-map',
  standalone: true,
  imports: [AngularOpenlayersModule, CommonModule, DialogModule],
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
})
export class CustomMapComponent implements OnInit, AfterViewInit, OnChanges {
  currentUserLocation: { x: number; y: number } = { x: 0, y: 0 };
  error: string = '';
  acceptedLocationPermission: boolean = true;
  @Input({ required: true }) displayMap: boolean = false;
  @Input({ required: true }) data!: any;

  selectedMarker: any;
  selectedAddress: { fullAddress: string; lat: number; lon: number } | null =
    null;
  entityCoordinates: LocationMarker[] = [];
  map!: Map;
  overlay!: Overlay;
  dcLayer!: VectorLayer<VectorSource>;
  popupPosition = { x: 0, y: 0 };
  private styleCache: { [key: string]: Style } = {};
  router = inject(Router);

  ngOnInit() {}

  ngAfterViewInit() {
    this.initializeMap();
    this.entityCoordinates = this.getEntityCoordinates(this.data);
    this.getUserLocation();
    this.updateMapMarkers();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.clearClusterLayer();
      this.entityCoordinates = this.getEntityCoordinates(
        changes['data'].currentValue
      );
      if (this.map) {
        this.updateMapMarkers();
      }
    }
  }
  clearClusterLayer() {
    if (this.map?.getLayers()) {
      const layers = this.map?.getLayers()?.getArray();
      layers.forEach((layer) => {
        if (
          layer instanceof VectorLayer &&
          layer.getSource() instanceof Cluster
        ) {
          this.map.removeLayer(layer);
        }
      });
    }
  }

  getUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.currentUserLocation.x = position.coords.longitude;
          this.currentUserLocation.y = position.coords.latitude;
          this.centerMapOnUserLocation();
        },
        (error) => {
          console.error('Location permission denied', error);
        }
      );
    }
  }

  centerMapOnUserLocation() {
    if (this.map) {
      const userCoordinates = fromLonLat([
        this.currentUserLocation.x,
        this.currentUserLocation.y,
      ]);
      this.map.getView().setCenter(userCoordinates);
      this.addUserLocationMarker();
    }
  }

  addUserLocationMarker() {
    const userFeature = new Feature({
      geometry: new Point(
        fromLonLat([this.currentUserLocation.x, this.currentUserLocation.y])
      ),
    });
    userFeature.setStyle(
      new Style({
        image: new Icon({
          src: 'assets/demo/images/my-location-pin.png',
          scale: 0.1,
        }),
      })
    );

    const userLocationSource = new VectorSource({
      features: [userFeature],
    });
    const userLocationLayer = new VectorLayer({
      source: userLocationSource,
    });
    this.map.addLayer(userLocationLayer);
  }

  initializeMap() {
    const popupElement = document.getElementById('popup') as HTMLElement;
    this.overlay = new Overlay({
      element: popupElement,
      autoPan: { animation: { duration: 250 } },
    });

    this.map = new Map({
      target: 'map',
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      overlays: [this.overlay],
      view: new View({
        center: fromLonLat([
          this.currentUserLocation.x,
          this.currentUserLocation.y,
        ]),
        zoom: 12,
      }),
      controls: defaultControls({ attribution: false }).extend([
        new FullScreen(),
        new ScaleLine(),
      ]),
    });

    const selectClick = new Select({
      condition: click,
      hitTolerance: 5,
    });

    this.map.addInteraction(selectClick);

    selectClick.on('select', (e) => {
      const feature = e.selected[0];
      if (feature) {
        const features = feature.get('features');
        if (features && features.length > 1) {
          const extent = boundingExtent(
            features.map((f: any) => f.getGeometry().getCoordinates())
          );
          this.map
            .getView()
            .fit(extent, { duration: 1000, padding: [50, 50, 50, 50] });
        } else if (features[0].get('data')) {
          this.showOverlayForFeature(features[0]);
        }
      } else {
        this.overlay.setPosition(undefined);
        this.selectedMarker = null;
        this.selectedAddress = null;
      }
    });
  }

  showOverlayForFeature(feature: any) {
    this.selectedMarker = feature.get('data') || null;
    this.selectedAddress = feature.get('addresses') || null;

    if (this.selectedAddress) {
      const coordinate = fromLonLat([
        this.selectedAddress.lon,
        this.selectedAddress.lat,
      ]);
      const geometry = feature.getGeometry();
      if (geometry instanceof Point) {
        const coordinate = geometry.getCoordinates();
        this.map.getView().animate({ center: coordinate, duration: 1000 });
      }
      this.overlay.setPosition(coordinate);
    } else {
      this.overlay.setPosition(undefined);
    }
  }

  getEntityCoordinates(data: Doctor[] | DiagnosticCenter[]): any {
    if (Array.isArray(data) && data.length > 0) {
      if (data[0].hasOwnProperty('doctorId')) {
        return data.flatMap((doctor: any) =>
          doctor.addresses.map((address: any) => ({
            x: address.lon,
            y: address.lat,
            name: doctor.fullName,
            fullAddress: address.fullAddress,
            data: doctor,
            addresses: doctor.addresses,
          }))
        );
      } else if (data[0].hasOwnProperty('clinicId')) {
        return data.flatMap((center: any) =>
          center.clinicAddresses.map((address: any) => ({
            x: address.lon,
            y: address.lat,
            name: center.name,
            fullAddress: address.fullAddress,
            data: center,
            addresses: center.addresses,
          }))
        );
      }
    }
    return [];
  }

  updateMapMarkers() {
    const doctorFeatures = this.entityCoordinates.map((location) => {
      const feature = new Feature({
        geometry: new Point(fromLonLat([location.x, location.y])),
      });

      feature.setProperties({
        data: location.data,
        addresses: {
          fullAddress: location.fullAddress,
          lat: location.y,
          lon: location.x,
        },
      });

      feature.setStyle(
        new Style({
          image: new Icon({
            src: 'assets/demo/images/location-pin.png',
            scale: 0.1,
          }),
        })
      );

      return feature;
    });

    const source = new VectorSource({
      features: doctorFeatures,
    });

    const clusterSource = new Cluster({
      distance: 40,
      source: source,
    });

    const clusters = new VectorLayer({
      source: clusterSource,
      style: (feature) => this.clusterStyle(feature),
    });

    this.map.addLayer(clusters);
  }

  private clusterStyle(feature: any): Style {
    const size = feature.get('features').length || 0;
    if (!this.styleCache[size]) {
      this.styleCache[size] = new Style({
        image: new CircleStyle({
          radius: 10 + size,
          stroke: new Stroke({
            color: '#fff',
          }),
          fill: new Fill({
            color: '#d92934',
          }),
        }),
        text: new Text({
          text: size.toString(),
          font: '12px Arial, sans-serif',
          fill: new Fill({
            color: '#fff',
          }),
        }),
      });
    }
    return this.styleCache[size];
  }

  closeDetails() {
    this.overlay.setPosition(undefined);
    this.selectedMarker = null;
    this.selectedAddress = null;
  }

  navigateToDetails(doctor: any) {
    this.router.navigate(['doctor-details', doctor.userId]);
  }
}
