<div class="grid">
  <div class="col-12">
    <div class="card">
      <p-accordion>
        <p-accordionTab
          header="Φίλτρα"
          [selected]="false"
          class="line-height-3 m-0"
        >
          <div class="mt-3 p-fluid">
            <div class="p-formgrid grid">
              <div class="field col-12 sm:col-4">
                <label class="font-bold" for="visit-from"
                  >Ημ. Επίσκεψης (Aπό)</label
                >
                <input pInputText id="visit-from" type="date" />
              </div>
              <div class="field col-12 sm:col-4">
                <label class="font-bold" for="visit-to"
                  >Ημ. Επίσκεψης (Μέχρι)</label
                >
                <input pInputText id="visit-to" type="date" />
              </div>

              <div class="field col-12 sm:col-4">
                <label class="font-bold" for="birth-from">Ημ. Γέννησης</label>
                <input pInputText id="birth-from" type="date" />
              </div>
            </div>

            <div class="p-formgrid grid">
              <div class="field col-12 sm:col-4">
                <label class="font-bold" for="gender">Φύλο</label>
                <input pInputText id="gender" type="text" />
              </div>

              <div class="field col-12 sm:col-4">
                <label class="font-bold" for="with-referral"
                  >Με Παραπομπή</label
                >
                <input pInputText id="with-referral" type="text" />
              </div>

              <div class="field col-12 sm:col-4">
                <label class="font-bold" for="with-diagnosis"
                  >Με Γνωμάτευση</label
                >
                <input pInputText id="with-diagnosis" type="text" />
              </div>
            </div>
            <div class="p-formgrid grid">
              <div class="field col-12 sm:col-4">
                <label class="font-bold" for="exam-type">Είδος Εξέτασης</label>
                <input pInputText id="exam-type" type="text" />
              </div>
              <div class="field col-12 sm:col-4">
                <label class="font-bold" for="diagnosis-code"
                  >Κωδικός Διάγνωσης</label
                >
                <input pInputText id="diagnosis-code" type="text" />
              </div>
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>
</div>
<div class="card">
  <h5>Πίνακας Ασθενών</h5>
  <p-table
    #dt2
    [value]="insured"
    dataKey="id"
    [rows]="10"
    [rowsPerPageOptions]="[10, 25, 50]"
    [paginator]="true"
    [lazy]="false"
    [globalFilterFields]="[
      'socialSecurityNumber',
      'fullName',
      'gender',
      'email'
    ]"
    [tableStyle]="{ 'min-width': '70rem' }"
  >
    <ng-template pTemplate="caption">
      <div class="flex justify-content-between flex-wrap row-gap-2">
        <div class="flex flex-wrap gap-3">
          <input
            pInputText
            type="text"
            (input)="onGlobalFilter(dt2, $event)"
            placeholder="Αναζήτηση"
          />
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 10%">AMKA</th>
        <th style="width: 10%">Ονοματεπώνυμο</th>
        <th style="width: 10%">Ημ.Γέννησης</th>
        <th style="width: 10%">Φύλο</th>
        <th style="width: 10%">Κινητό</th>
        <th style="width: 10%">Email</th>
        <th style="width: 10%"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-insured>
      <tr>
        <td>
          {{ insured.socialSecurityNumber }}
        </td>
        <td>
          {{ insured.fullName }}
        </td>
        <td>
          {{ insured.dateOfBirth | date : "dd/MM/YYYY" }}
        </td>
        <td>
          {{ insured.gender }}
        </td>
        <td>
          {{ insured.mobile }}
        </td>
        <td>
          {{ insured.email }}
        </td>
        <td>
          <a [routerLink]="['/patient-details/', insured.userId]"
            ><i class="pi pi-arrow-circle-right"></i>
          </a>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="5">Δεν βρέθηκαν ασθενείς.</td>
      </tr>
    </ng-template>
  </p-table>

  <!-- <p-sidebar [(visible)]="sidebarVisible" styleClass="w-30rem">
    <div class="flex flex-column">
      <h3>Επιλογή Εξειδικευμένων Φίλτρων</h3>

      <div class="flex flex-row align-items-center gap-5">
        <button
          pButton
          pRipple
          class="p-button-success"
          label="Προβολή"
        ></button>
        <div pButton pRipple class="border-none bg-white">
          <i class="pi pi-sync text-4xl text-color text-bold"></i>
        </div>
      </div>

      <h6>Ημερομηνία Επίσκεψης</h6>
      <div class="mt-3 p-fluid">
        <div class="p-formgrid grid">
          <div class="field col-6">
            <label class="font-bold" htmlFor="name2">Άπο</label>
            <input pInputText id="name2" type="date" />
          </div>
          <div class="field col-6">
            <label class="font-bold" htmlFor="email2">Μέχρι</label>
            <input pInputText id="email2" type="date" />
          </div>
        </div>
        <h6>Ημερομηνία Γέννησης</h6>
        <div class="p-formgrid grid">
          <div class="field col-6">
            <label class="font-bold" htmlFor="name2">Άπο</label>
            <input pInputText id="name2" type="date" />
          </div>
          <div class="field col-6">
            <label class="font-bold" htmlFor="email2">Μέχρι</label>
            <input pInputText id="email2" type="date" />
          </div>
          <div class="field col-12">
            <label class="font-bold" htmlFor="name2">Φύλο</label>
            <input pInputText id="name2" type="text" />
          </div>
          <div class="field col-12">
            <label class="font-bold" htmlFor="email2">Με Παραπομπή</label>
            <input pInputText id="email2" type="text" />
          </div>
          <div class="field col-12">
            <label class="font-bold" htmlFor="name2">Με Γνωμάτευση</label>
            <input pInputText id="name2" type="text" />
          </div>
          <div class="field col-12">
            <label class="font-bold" htmlFor="email2">Είδος Εξέτασης</label>
            <input pInputText id="email2" type="text" />
          </div>
          <div class="field col-12">
            <label class="font-bold" htmlFor="email2">Κωδικός Διάγνωσης</label>
            <input pInputText id="email2" type="text" />
          </div>
        </div>
      </div>
    </div>
  </p-sidebar> -->
</div>
