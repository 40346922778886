<div class="grid" style="height: 50em">
  <div class="col-12 card flex flex-column">
    <h3>Account Settings</h3>
    <div
      class="flex flex-row justify-content-between align-items-center flex-wrap gap-3"
    >
      <h6 class="text-center p-0 m-0">Sync Calendar</h6>
      <div class="flex flex-row align-items-center gap-3">
        <img src="/assets/images/google-calendar-48x48.png" alt="" srcset="" />
        @if(syncedCalendar['google']==false){
        <h6>Google Calendar</h6>
        <i
          class="pi pi-sync font-bold cursor-pointer"
          (click)="syncCalendar('google')"
        ></i>
        }@else{
        <h6>Synced</h6>
        }
      </div>
      <div class="flex flex-row align-items-center gap-3">
        <img src="/assets/images/yahoo-calendar-48x48.png" alt="" srcset="" />
        @if(syncedCalendar['yahoo']==false){
        <h6>Yahoo Calendar</h6>
        <i
          class="pi pi-sync font-bold cursor-pointer"
          (click)="syncCalendar('yahoo')"
        ></i>
        }@else{
        <h6>Synced</h6>
        }
      </div>
      <div class="flex flex-row align-items-center gap-3">
        <img
          src="/assets/images/microsoft-calendar-48x48.png"
          alt=""
          srcset=""
        />@if(syncedCalendar['microsoft']==false){
        <h6>Microsoft Calendar</h6>
        <i
          class="pi pi-sync font-bold cursor-pointer"
          (click)="syncCalendar('microsoft')"
        ></i>
        }@else{
        <h6>Synced</h6>
        }
      </div>
      <div class="flex flex-row align-items-center gap-3">
        <img
          src="/assets/images/apple-calendar-48x48.png"
          alt=""
          srcset=""
        />@if(syncedCalendar['apple']==false){
        <h6>Apple Calendar</h6>
        <i
          class="pi pi-sync font-bold cursor-pointer"
          (click)="syncCalendar('apple')"
        ></i>
        }@else{
        <h6>Synced</h6>
        }
      </div>
    </div>
  </div>
</div>
