import { DatePipe, CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { RippleModule } from 'primeng/ripple';
import { TableModule, Table } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { Subscription } from 'rxjs';
import { AppointmentStatus } from '../../../enums/appointment-status.constants';
import { ModalMessage } from '../../../enums/modal-messages.constants';
import { AppointmentsService } from '../../../../public-api';

@Component({
  selector: 'lib-appointments-table',
  standalone: true,
  imports: [
    TableModule,
    DropdownModule,
    CalendarModule,
    FormsModule,
    TagModule,
    DatePipe,
    ButtonModule,
    RippleModule,
    CommonModule,
    DialogModule,
  ],
  templateUrl: './appointments-table.component.html',
  styleUrl: './appointments-table.component.css',
})
export class AppointmentsTableComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() appointments: any;
  @Input() pendingAppointments: any;
  @Input() tableHeaders!: { field: string; header: string }[];

  @Output() appointmentsChange = new EventEmitter<any[]>();
  @Output() pendingAppointmentsChange = new EventEmitter<any[]>();

  status: any = [
    { label: AppointmentStatus.APPROVED, value: 1 },
    { label: 'Απορρίφθηκε', value: 2 },
    { label: AppointmentStatus.PENDING, value: 3 },
    { label: AppointmentStatus.REJECTED, value: 4 },
    { label: AppointmentStatus.COMPLETED, value: 5 },
  ];
  selectedStatus: any;
  selectedDate: any;
  notes = 'Επείγον';
  clonedAppointments: any[] = [];
  statuses = [
    { label: AppointmentStatus.APPROVED, value: 1 },
    { label: 'Απορρίφθηκε', value: 2 },
    // { label: AppointmentStatus.PENDING, value: 3 },
    { label: AppointmentStatus.REJECTED, value: 4 },
    { label: AppointmentStatus.COMPLETED, value: 5 },
  ];
  successVisible: boolean = false;
  errorVisible: boolean = false;

  modalMessages = ModalMessage;

  private appointmentService = inject(AppointmentsService);
  private router = inject(Router);
  subscriptions = new Subscription();

  ngOnInit(): void {}
  ngOnDestroy(): void {}
  ngOnChanges(changes: SimpleChanges): void {}
  onRowEditInit(appointment: any) {
    this.clonedAppointments[appointment.appointmentId] = { ...appointment };
  }

  onRowEditSave(appointment: any) {
    if (appointment.appointmentId > 0) {
      delete this.clonedAppointments[appointment.appointmentId];

      let appointmentStatus = {
        appointmentStatusId: appointment.appointmentStatus.appointmentStatusId,
      };
      const approveAppointmentService$ = this.appointmentService
        .updateAppointmentStatusById(
          appointment.appointmentId,
          appointmentStatus
        )
        .subscribe({
          next: (res) => {
            this.statuses.map((x) => {
              if (x.value == appointmentStatus.appointmentStatusId) {
                appointment.appointmentStatus.name = x.label;
                appointment.appointmentStatus.appointmentStatusId =
                  appointmentStatus.appointmentStatusId;
              }
            });

            if (appointmentStatus.appointmentStatusId == '5') {
              this.router.navigate(['patient-details', appointment.userId]);
            } else if (appointmentStatus.appointmentStatusId == '3') {
              this.pendingAppointments = [
                ...this.pendingAppointments,
                appointment,
              ];
              this.pendingAppointmentsChange.emit(this.pendingAppointments);
            }
            this.successVisible = true;
            // emit the appointments with the new status
            this.appointmentsChange.emit(this.appointments);
          },
          error: (error: any) => {
            this.errorVisible = true;
            // console.error('Error approving appointment:', error);
          },
          complete: () => {},
        });
      this.subscriptions.add(approveAppointmentService$);
    }
  }

  onRowEditCancel(appointment: any, index: number) {
    this.appointments[index] =
      this.clonedAppointments[appointment.appointmentId];
    delete this.clonedAppointments[appointment.appointmentId];
  }

  onStatusChange(event: any, dt2: Table) {
    dt2.filter(event.value, 'appointmentStatus.appointmentStatusId', 'equals');
  }

  // Method to handle date change and reset the filter when cleared
  onDateChange(event: any, dt2: Table) {
    if (event === null || event === '') {
      this.resetFilter(dt2);
    } else {
      this.filterByDate(dt2);
    }
  }
  // Method to reset the filter and show all rows
  resetFilter(dt2: Table) {
    this.selectedDate = null;
    dt2.clear();
  }
  filterByDate(dt2: Table) {
    if (this.selectedDate) {
      const formattedDate = this.formatDate(this.selectedDate);
      dt2.filter(formattedDate, 'appointmentDate', 'equals');
    }
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  getSeverity(status: any) {
    switch (status) {
      case 'Επιβεβαιωμένο':
        return 'info';
      case 'Απορρίφθηκε':
        return 'danger';
      case 'Εκκρεμεί':
        return 'warning';
      case 'Ακυρωμένο':
        return 'danger';
      case 'Ολοκληρωμένο':
        return 'success';
      case 'Απόρριψη':
        return 'danger';

      case 1:
        return 'info';
      case 2:
        return 'danger';
      case 3:
        return 'warning';
      case 4:
        return 'danger';
      case 5:
        return 'success';
      default:
        return undefined;
    }
  }
  getExamStringValue(num: number) {
    switch (num) {
      case 1:
        return 'Υπό-Επεξεργασία';
      case 2:
        return 'Απορρίφθηκε';
      case 3:
        return 'Εκκρεμεί';
      case 4:
        return 'Ακυρωμένο';
      case 5:
        return 'Ολοκληρωμένο';
      default:
        return 'N/A';
    }
  }
}
