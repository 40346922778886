import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TabViewModule } from 'primeng/tabview';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { ListboxModule } from 'primeng/listbox';
import { ChipModule } from 'primeng/chip';
import { catchError, forkJoin, of, Subscription } from 'rxjs';
import {
  DoctorService,
  ExamsService,
  ImportsModule,
  MemberService,
  ModalMessage,
} from 'shared';

@Component({
  selector: 'app-patient-details',
  standalone: true,
  imports: [
    ImportsModule,
    TabViewModule,
    FileUploadModule,
    ToastModule,
    ListboxModule,
    ChipModule,
  ],
  templateUrl: './patient-details.component.html',
  styleUrl: './patient-details.component.scss',
})
export class PatientDetailsComponent implements OnInit, OnDestroy {
  diagnoses: any = [
    {
      icd10: 'A00',
      diagnosis: 'Χολέρα',
      comment: 'Χωρίς πρόσφατο ιστορικό ταξιδιού',
      event: 'Ξέσπασμα',
      startDate: new Date('2024-01-10'),
      outcome: 'Ανάρρωση',
      endDate: new Date('2024-01-20'),
    },
    {
      icd10: 'B20',
      diagnosis: 'Νόσος του HIV',
      comment: 'Υπό θεραπεία ART',
      event: 'Προγραμματισμένος έλεγχος',
      startDate: new Date('2023-12-05'),
      outcome: 'Σταθερή',
      endDate: new Date('2024-01-15'),
    },
    // Add more data as needed
  ];
  records = [
    {
      date: new Date('2024-08-20'),
      title: 'Εξετάσεις αίματος',
      category: 'Δια ζώσης',
      reason: 'Ρουτίνα',
      notes: 'Αναμονή αποτελεσμάτων',
    },
    {
      date: new Date('2024-07-15'),
      title: 'Συνάντηση με γιατρό',
      category: 'Βιντεοκλήση',
      reason: 'Ανασκόπηση φαρμακευτικής αγωγής',
      notes: 'Συνεχίστε με την ίδια αγωγή',
    },
    {
      date: new Date('2024-06-10'),
      title: 'Διαγνωστικές εξετάσεις',
      category: 'Δια ζώσης',
      reason: 'Συμπτώματα άσθματος',
      notes: 'Χρειάζεται περαιτέρω αξιολόγηση',
    },
    {
      date: new Date('2024-05-22'),
      title: 'Ψυχιατρική συνεδρία',
      category: 'Βιντεοκλήση',
      reason: 'Παρακολούθηση',
      notes: 'Αναφορά βελτίωσης',
    },
  ];
  examinations = [
    {
      date: new Date('2024-08-20'),
      category: 'Αιματολογικές',
      examination: 'Ολική Αιματολογική Εξέταση',
      result: 'Κανονικά επίπεδα',
    },
    {
      date: new Date('2024-07-15'),
      category: 'Ακτινολογικές',
      examination: 'Ακτινογραφία Θώρακος',
      result: 'Χωρίς ευρήματα',
    },
    {
      date: new Date('2024-06-10'),
      category: 'Βιοχημικές',
      examination: 'Εξέταση Γλυκόζης',
      result: 'Ελαφρώς αυξημένη',
    },
    {
      date: new Date('2024-05-22'),
      category: 'Ουρολογικές',
      examination: 'Ανάλυση Ούρων',
      result: 'Χωρίς ανωμαλίες',
    },
  ];

  files: any = [
    {
      fileName: 'Patient_Record_01.pdf',
      dateOfUpload: new Date('2024-08-01'),
      fileSize: '1.5 MB',
    },
    {
      fileName: 'XRay_Image_02.png',
      dateOfUpload: new Date('2024-07-28'),
      fileSize: '2.3 MB',
    },
    {
      fileName: 'Blood_Test_Report_03.docx',
      dateOfUpload: new Date('2024-07-15'),
      fileSize: '250 KB',
    },
    {
      fileName: 'MRI_Scan_04.jpg',
      dateOfUpload: new Date('2024-06-30'),
      fileSize: '5.6 MB',
    },
    {
      fileName: 'Prescription_05.pdf',
      dateOfUpload: new Date('2024-05-22'),
      fileSize: '800 KB',
    },
  ];

  exams: any;
  selectedExams: any;
  icd10: string = '';
  selectedIcd10: any = '';
  textAreaValue: string = '';
  modalMessage: any;
  insured: any;
  clinicalExams: any;

  showHospitalizationDialog = false;

  

  selectedDateForAppointment: any;

  subscriptions: Subscription[] = [];
  name: any;
  uploadedFiles: any[] = [];

  showPrescription: boolean = false;
  showReference: boolean = false;
  doctorName: any;
  dataLoaded: boolean = false;
  successVisible: boolean = false;
  errorVisible: boolean = false;
  requestAccessDialog: boolean = false;
  requestAccessDisabled: boolean = false;
  @ViewChildren('buttonEl') buttonEl!: QueryList<ElementRef>;

  userId: number = 0;
  doctor: any;

  constructor(
    private route: ActivatedRoute,
    private examService: ExamsService,
    private doctorService: DoctorService,
    private patientService: MemberService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}
  ngOnInit(): void {
    this.userId = Number(this.route.snapshot.paramMap.get('id'));
    this.doctorName = JSON.parse(localStorage.getItem('token')!);
    this.fetchData();
    this.doctorName.userName = this.doctorName.userName
      .split('_') // Split into ["rachel", "stark"]
      .map((namePart: any) => {
        return namePart.charAt(0).toUpperCase() + namePart.slice(1);
      }) // Capitalize each part
      .join(' ');
  }
  fetchData() {
    const doctorServiceSubscription$ = this.doctorService
      .getDoctorDataByUsername(this.doctorName.userName)
      .subscribe((doctor) => {
        if (doctor) {
          console.log(doctor);
          this.doctor = doctor;
          const patientServiceSubscription$ = this.patientService
            .getMemberDetailsById(this.userId)
            .pipe(
              catchError((err) => {
                return of([]);
              })
            )
            .subscribe({
              next: (patientResult) => {
                if (patientResult) {
                  this.insured = patientResult.data;
                }
              },
            });
          const clinicalExamsSubscription$ = this.patientService
            .getInsuredClinicalExamsByMemberId(this.userId)
            .subscribe((patientResult) => {
              if (patientResult) {
                this.clinicalExams = patientResult.data;
              }
            });

          const examTypeSubscription$ = this.examService.getExamTypes();
          const subscriptions$ = forkJoin([examTypeSubscription$]).subscribe({
            next: ([examTypeResult]) => {
              if (examTypeResult) {
                this.exams = examTypeResult.examTypes.map(
                  (exam: { name: any; examTypeId: any }) => {
                    return {
                      name: exam.name,
                      code: exam.examTypeId,
                    };
                  }
                );
              }
            },
            error: (error) => {
              console.error('Error fetching data:', error);
            },
            complete: () => {
              this.dataLoaded = true;
            },
          });

          this.subscriptions.push(subscriptions$);
        }
      });
  }
  ngOnDestroy(): void {
    this.subscriptions.pop();
  }

  onUpload(event: any) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }

    // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'File uploaded successfully' });
  }

  onImageMouseOver(file: any) {
    this.buttonEl.toArray().forEach((el) => {
      el.nativeElement.id === file.name
        ? (el.nativeElement.style.display = 'flex')
        : null;
    });
  }

  onImageMouseLeave(file: any) {
    this.buttonEl.toArray().forEach((el) => {
      el.nativeElement.id === file.name
        ? (el.nativeElement.style.display = 'none')
        : null;
    });
  }

  removeImage(event: Event, file: any) {
    event.stopPropagation();
    this.uploadedFiles = this.uploadedFiles.filter((i) => i !== file);
  }

  showAddPrescription() {
    this.showPrescription = true;
  }

  formatDate(date: any): string {
    // Split the input date string into day, month, and year
    const [day, month, year] = date.split('/');

    // Return the date in the desired yyyy/mm/dd format
    return `${day}`;
  }

  formatTime(timeStr: string): string {
    // Extract the hours and minutes part from the string
    let [time, period] = timeStr.split(' ');
    let [hours, minutes] = time.split(':').map(Number);

    // Convert to 24-hour format if needed
    if (period === 'PM' && hours < 12) {
      hours += 12;
    }
    if (period === 'AM' && hours === 12) {
      hours = 0;
    }

    // Format the hours and minutes with leading zeros
    let formattedHours = hours.toString().padStart(2, '0');
    let formattedMinutes = minutes.toString().padStart(2, '0');

    // Combine into the desired format
    return `${formattedHours}:${formattedMinutes}:00`;
  }

  isCode(input: string): boolean {
    // Updated regex to match a letter followed by 1 or 2 digits, optionally followed by a decimal and more digits
    const codePattern = /^[A-Za-z]\d{1,2}(\.\d+)?$/;
    return codePattern.test(input);
  }

  searchIcd10() {
    if (this.isCode(this.icd10)) {
      this.examService
        .getICD10ByNameOrCode(undefined, this.icd10)
        .subscribe((res) => {
          this.selectedIcd10 = res.data;
        });
    } else {
      this.examService
        .getICD10ByNameOrCode(this.icd10, undefined)
        .subscribe((res) => {
          this.selectedIcd10 = res.data;
        });
    }
  }

  // requestAccess() {
  //   this.examService
  //     .requestAccessToMedicalRecord(this.doctor.data.userId, this.userId)
  //     .subscribe((res) => {
  //       this.requestAccessDisabled = true;
  //       if (res) {
  //         this.requestAccessDialog = false;
  //       }
  //     });
  // }

  addReference() {
    if (
      this.selectedIcd10 &&
      (this.selectedExams || this.selectedExams.length > 0)
    ) {
      let reference: any = {
        userId: this.userId,
        requestUserId: this.doctor.data.userId,
        startDate: this.calculateDate(0),
        expiryDate: this.calculateDate(2),
        icd10id: this.selectedIcd10[0].icd10Id,
        examTypes: this.selectedExams.map((item: any) => {
          return item.code;
        }),
        notes: this.textAreaValue,
      };
      console.log(this.selectedExams);
      const createExamSubscription$ = this.examService
        .createExams(reference)
        .subscribe({
          next: (res) => {
            this.modalMessage = this.formValidator(res);
            this.icd10 = '';
            this.selectedIcd10 = '';
          },
          error: (err) => {
            console.error(err);
            this.showReference = false;
            this.errorVisible = true;
          },
          complete: () => {
            /*checks if the validation message from the api is null,
             which means no validation errors and closes the modal */
            if (this.modalMessage != null) {
              this.successVisible = true;
              this.showReference = false;
            }
          },
        });

      this.subscriptions.push(createExamSubscription$);
    }
  }

  addHospitalization(event: any) {
    const button = event.target as HTMLButtonElement;
    button.disabled = true;
    console.log(event);

    setTimeout(() => {
      this.successVisible = true;
      this.showHospitalizationDialog = false;
    }, 1000);
  }
  // Helper function to calculate the expiry date
  calculateDate(number: number): string {
    const date = new Date(); // Get today's date
    date.setMonth(date.getMonth() + number);

    // Handle end of month scenarios
    if (date.getDate() < new Date().getDate()) {
      date.setDate(0);
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure 2-digit month
    const day = String(date.getDate()).padStart(2, '0'); // Ensure 2-digit day

    return `${year}-${month}-${day}`;
  }

  formValidator(message: any) {
    if (message.validationMessages)
      return message.validationMessages.map((m: any) => {
        return m;
      });
    else {
      return '';
    }
  }

  addMedicine() {
    // Reference the parent container where the new row will be added
    const parent = this.el.nativeElement.querySelector('#medicines');

    // Create the new row container
    const newRow = this.renderer.createElement('div');
    this.renderer.addClass(newRow, 'flex');
    this.renderer.addClass(newRow, 'gap-5');
    this.renderer.addClass(newRow, 'align-items-center'); // Align items vertically centered

    // Create the first div (for the "Φάρμακο" input)
    const medicineDiv = this.renderer.createElement('div');
    this.renderer.addClass(medicineDiv, 'flex');
    this.renderer.addClass(medicineDiv, 'flex-column');
    this.renderer.addClass(medicineDiv, 'gap-2');

    // Create the label for "Φάρμακο"
    const medicineLabel = this.renderer.createElement('label');
    this.renderer.setAttribute(medicineLabel, 'for', 'medicineInput');
    this.renderer.addClass(medicineLabel, 'font-semibold');
    const medicineLabelText = this.renderer.createText('Φάρμακο');
    this.renderer.appendChild(medicineLabel, medicineLabelText);

    // Create the input for "Φάρμακο"
    const medicineInput = this.renderer.createElement('input');
    this.renderer.setAttribute(medicineInput, 'pInputText', '');
    this.renderer.setAttribute(medicineInput, 'autocomplete', 'off');
    this.renderer.addClass(medicineInput, 'flex-auto');
    this.renderer.addClass(medicineInput, 'p-inputtext');

    // Append label and input to the medicineDiv
    this.renderer.appendChild(medicineDiv, medicineLabel);
    this.renderer.appendChild(medicineDiv, medicineInput);

    // Create the second div (for the "Ποσότητα" input)
    const quantityDiv = this.renderer.createElement('div');
    this.renderer.addClass(quantityDiv, 'flex');
    this.renderer.addClass(quantityDiv, 'flex-column');
    this.renderer.addClass(quantityDiv, 'gap-2');

    // Create the label for "Ποσότητα"
    const quantityLabel = this.renderer.createElement('label');
    this.renderer.setAttribute(quantityLabel, 'for', 'quantityInput');
    this.renderer.addClass(quantityLabel, 'font-semibold');
    const quantityLabelText = this.renderer.createText('Ποσότητα');
    this.renderer.appendChild(quantityLabel, quantityLabelText);

    // Create the input for "Ποσότητα"
    const quantityInput = this.renderer.createElement('input');
    this.renderer.setAttribute(quantityInput, 'pInputText', '');
    this.renderer.setAttribute(quantityInput, 'autocomplete', 'off');
    this.renderer.addClass(quantityInput, 'flex-auto');
    this.renderer.addClass(quantityInput, 'p-inputtext');

    // Append label and input to the quantityDiv
    this.renderer.appendChild(quantityDiv, quantityLabel);
    this.renderer.appendChild(quantityDiv, quantityInput);

    // Append both input divs to the new row
    this.renderer.appendChild(newRow, medicineDiv);
    this.renderer.appendChild(newRow, quantityDiv);

    // Append the new row to the parent container
    this.renderer.appendChild(parent, newRow);
  }

  showAddReference() {
    this.showReference = true;
  }
}
