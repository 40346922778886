import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import {
  AuthService,
  ChatService,
  MemberService,
  MenuDataService,
} from 'shared';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  model: any = [
    {
      label: '',
      icon: 'pi pi-fw pi-sitemap',
      items: [
        {
          label: 'Πίνακας Ελέγχου',
          icon: 'pi pi-fw pi-home',
          routerLink: [''],
        },
        {
          label: 'Ατζέντα',
          icon: 'pi pi-fw pi-calendar',
          routerLink: ['/calendar'],
        },
        {
          label: 'Βάση Ασθενών',
          icon: 'pi pi-fw pi-users',
          routerLink: ['/manage-patients'],
        },
        {
          label: 'Συνταγογράφηση',
          icon: 'pi pi-fw pi-heart',
          routerLink: ['/prescriptions'],
        },

        {
          label: 'Σύνθεση Ιατρείου',
          icon: 'pi pi-fw pi-warehouse',
          routerLink: ['***'],
        },
      ],
    },
  ];

  constructor(
    private primengConfig: PrimeNGConfig,
    private menuDataService: MenuDataService,
    private memberService: MemberService,
    private authService: AuthService,
    private chatService: ChatService
  ) {}

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.menuDataService.update(this.model);
    this.memberService.appName = 'doctor';
    const user = JSON.parse(localStorage.getItem('token')!);
    if (user) {
      this.authService.fetchUser(user).subscribe(() => {
        this.chatService.createCurrentSession();
      });
    }
  }
}
