<div class="grid">
  <div class="col-12 card flex lg:justify-content-end align-items-center">
    <p-menubar [model]="items" />
  </div>
  @if(selectedMenuItem==="calendar"){
  <div class="col-12 card">
    <lib-full-calendar [appointments]="appointments"></lib-full-calendar>
  </div>
  }@else if(selectedMenuItem==="appointments"){
  <div class="col-12 card">
    <lib-appointments-table
      [(appointments)]="appointments"
      [(pendingAppointments)]="pendingAppointments"
      [tableHeaders]="tableHeaders"
    ></lib-appointments-table>
  </div>
  } @else if(selectedMenuItem=="pendingappointments"){
  <div class="col-12 card">
    <lib-pending-appointments
      [(appointments)]="pendingAppointments"
      [confirmed_patient_history]="confirmed_patient_history"
      (changedAppointments)="getPendingAppointments($event)"
    ></lib-pending-appointments>
  </div>
  }
</div>
