<div class="grid">
  <div class="col align-items-center"></div>
  <div class="col-12 card flex lg:justify-content-end align-items-center">
    <p-menubar [model]="items" />
  </div>
  @if(selectedMenuItem==="calendar"){
  <div class="col-12 card">
    <div
      class="flex flex-column sm:flex-row gap-3 mb-3 justify-content-center align-items-start sm:align-items-center"
    >
      <div class="flex align-item-center gap-1">
        <p-badge [value]="" [style]="{ 'background-color': '#17a2b8' }" />
        <span class="font-bold">Επιβεβαιωμένο</span>
      </div>
      <div class="flex align-item-center gap-1">
        <p-badge [value]="" [style]="{ 'background-color': '#dc3545' }" />
        <span class="font-bold">Ακυρωμένο</span>
      </div>
      <div class="flex align-item-center gap-1">
        <p-badge [value]="" [style]="{ 'background-color': '#ffc107' }" />
        <span class="font-bold">Επόμενα ραντεβού</span>
      </div>
      <div class="flex align-item-center gap-1">
        <p-badge [value]="" [style]="{ 'background-color': '#28a745' }" />
        <span class="font-bold">Διεκπεραιωμένο</span>
      </div>
    </div>
    <full-calendar [options]="calendarOptions"></full-calendar>
    <p-dialog
      #dd
      [(visible)]="showDialog"
      [breakpoints]="{ '960px': '75vw', '640px': '90vw' }"
      [style]="{ width: '36rem' }"
      [modal]="true"
      [closable]="true"
      (onHide)="view = ''"
    >
      <ng-template pTemplate="header">
        <span class="text-900 font-semibold text-xl">{{
          view === "display"
            ? changedEvent.title
            : view === "new"
            ? "New Event"
            : "Edit Event"
        }}</span>
      </ng-template>
      <ng-template pTemplate="content">
        <div *ngIf="view === 'display'">
          <!-- <span class="text-900 font-semibold block mb-2">Description</span>
          <span class="block mb-3">{{ changedEvent.description }}</span> -->

          <div class="grid">
            <div class="col-6">
              <div class="text-900 font-semibold mb-2">Start</div>
              <p class="flex align-items-center m-0">
                <i class="pi pi-fw pi-clock text-700 mr-2"></i>
                <!-- <span>{{ changedEvent.start.toISOString().slice(0, 10) }}</span> -->
                <span>{{
                  changedEvent.start | date : "dd/MM/yyyy hh:mm a"
                }}</span>
              </p>
            </div>
            <div class="col-6">
              <div class="text-900 font-semibold mb-2">End</div>
              <p class="flex align-items-center m-0">
                <i class="pi pi-fw pi-clock text-700 mr-2"></i>
                <span>{{
                  changedEvent.end | date : "dd/MM/yyyy hh:mm a"
                }}</span>
              </p>
            </div>
            <div class="col-12">
              <div class="text-900 font-semibold mb-2">Description</div>
              <p class="flex align-items-center m-0">
                <i class="pi pi-fw pi-book text-700 mr-2"></i>
                <span>{{ changedEvent.description }}</span>
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="view !== 'display'">
          <div class="grid p-fluid formgrid">
            <div class="col-12 md:col-6 field">
              <label for="title" class="text-900 font-semibold">Title</label>
              <span class="p-input-icon-left">
                <i class="pi pi-pencil"></i>
                <input
                  id="title"
                  type="text"
                  pInputText
                  placeholder="Title"
                  [(ngModel)]="changedEvent.title"
                />
              </span>
            </div>
            <div class="col-12 md:col-6 field">
              <label for="location" class="text-900 font-semibold"
                >Location</label
              >
              <span class="p-input-icon-left">
                <i class="pi pi-map-marker"></i>
                <input
                  id="location"
                  type="text"
                  pInputText
                  placeholder="Location"
                  [(ngModel)]="changedEvent.location"
                />
              </span>
            </div>
            <div class="col-12 field">
              <label for="description" class="text-900 font-semibold"
                >Event Description</label
              >
              <textarea
                id="description"
                type="text"
                pInputTextarea
                [rows]="5"
                [(ngModel)]="changedEvent.description"
                style="resize: none"
              ></textarea>
            </div>

            <!-- <div class="col-12 md:col-6 field">
                        <label for="start" class="text-900 font-semibold">Start Date</label>
                        <p-calendar [appendTo]="dd" dateFormat="mm-dd-yy" [maxDate]="changedEvent.end" [showTime]="true" [required]="true" inputId="start" [(ngModel)]="changedEvent.start"></p-calendar>
                    </div>
                    <div class="col-12 md:col-6 field">
                        <label for="start" class="text-900 font-semibold">End Date</label>
                        <p-calendar [appendTo]="dd" dateFormat="mm-dd-yy" [minDate]="changedEvent.start" [showTime]="true" [required]="true" inputId="end" [(ngModel)]="changedEvent.end"></p-calendar>
                    </div> -->
            <div class="col-12 field">
              <label for="company-color" class="text-900 font-semibold"
                >Color</label
              >
              <p-dropdown
                inputId="company-color"
                [appendTo]="dd"
                [options]="tags"
                [(ngModel)]="changedEvent.tag"
                optionLabel="name"
              >
                <ng-template pTemplate="selectedItem">
                  <div *ngIf="changedEvent.tag" class="flex align-items-center">
                    <div
                      class="flex-shrink-0 w-1rem h-1rem mr-2 border-circle"
                      [style.backgroundColor]="changedEvent.tag.color"
                    ></div>
                    <div>{{ changedEvent.tag.name }}</div>
                  </div>
                </ng-template>
                <ng-template let-tag pTemplate="item">
                  <div class="flex align-items-center">
                    <div
                      class="flex-shrink-0 w-1rem h-1rem mr-2 border-circle"
                      [style.backgroundColor]="tag.color"
                    ></div>
                    <div>{{ tag.name }}</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
        </div>
      </ng-template>

      <!-- <ng-template pTemplate="footer">
        <button
          pButton
          *ngIf="view === 'display'"
          label="Edit"
          icon="pi pi-pencil"
          (click)="onEditClick()"
        ></button>
        <button
          pButton
          *ngIf="view === 'new' || view === 'edit'"
          label="Save"
          icon="pi pi-check"
          (click)="handleSave()"
          [disabled]="!changedEvent.start || !changedEvent.end"
        ></button>
      </ng-template> -->
    </p-dialog>
  </div>
  }@else if(selectedMenuItem==="appointments"){
  <div class="col-12 card">
    <p-table
      #dt2
      [value]="appointments"
      [paginator]="true"
      [rows]="10"
      dataKey="appointmentId"
      editMode="row"
      [tableStyle]="{ 'min-width': '75rem' }"
      [globalFilterFields]="['appointmentDate']"
      [rowsPerPageOptions]="[5, 10, 20]"
    >
      <ng-template pTemplate="caption">
        <div class="flex justify-content-end gap-3">
          <p-dropdown
            [options]="status"
            [(ngModel)]="selectedStatus"
            (ngModelChange)="onStatusChange($event, dt2)"
            optionLabel="label"
            placeholder="Όλα"
          />
          <p-calendar
            [(ngModel)]="selectedDate"
            [showIcon]="true"
            dateFormat="dd/mm/yy"
            (ngModelChange)="onDateChange($event, dt2)"
            [showButtonBar]="true"
          ></p-calendar>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>ΑΜΚΑ</th>
          <th>Όνοματεπώνυμο</th>
          <th>Ημερομηνία</th>
          <th>Ώρα</th>
          <th>Τύπος</th>
          <th>Σημειώσεις</th>
          <th>Κατάσταση</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-appointment
        let-editing="editing"
        let-ri="rowIndex"
      >
        <tr [pEditableRow]="appointment">
          <td>{{ appointment.socialSecurityNumber }}</td>
          <td>{{ appointment.fullName }}</td>
          <td>{{ appointment.appointmentDate | date : "dd/MM/yyyy" }}</td>
          <td>{{ appointment.appointmentTime }}</td>
          <td>{{ appointment.reasonOfVisit }}</td>
          <td>{{ notes }}</td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-dropdown
                  [options]="statuses"
                  appendTo="body"
                  optionLabel="label"
                  optionValue="value"
                  [(ngModel)]="
                    appointment.appointmentStatus.appointmentStatusId
                  "
                  [style]="{ width: '100%' }"
                />
              </ng-template>
              <ng-template pTemplate="output">
                <p-tag
                  [value]="appointment.appointmentStatus.name"
                  [severity]="getSeverity(appointment.appointmentStatus.name)"
                />
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <div class="flex align-items-center justify-content-center gap-2">
              <button
                *ngIf="!editing"
                pButton
                pRipple
                [disabled]="
                  appointment.appointmentStatus.appointmentStatusId == 3 ||
                  appointment.appointmentStatus.appointmentStatusId == 1
                    ? false
                    : true
                "
                type="button"
                pInitEditableRow
                icon="pi pi-pencil"
                (click)="onRowEditInit(appointment)"
                class="p-button-rounded p-button-text"
              ></button>
              <button
                *ngIf="editing"
                pButton
                pRipple
                type="button"
                pSaveEditableRow
                icon="pi pi-check"
                (click)="onRowEditSave(appointment)"
                class="p-button-rounded p-button-text p-button-success mr-2"
              ></button>
              <button
                *ngIf="editing"
                pButton
                pRipple
                type="button"
                pCancelEditableRow
                icon="pi pi-times"
                (click)="onRowEditCancel(appointment, ri)"
                class="p-button-rounded p-button-text p-button-danger"
              ></button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  } @else {
  <div class="col-12 card">
    <div class="grid row-gap-4">
      @for(appointment of pendingAppointments; track appointment){
      <div class="xl:col-3 md:col-6 sm:col-6 col-12">
        <div class="card surface-d">
          <div
            class="card-header flex-column flex-wrap justify-content-around gap-1"
          >
            <div
              class="flex flex-row align-items-center justify-content-between w-full"
            >
              <img
                [src]="appointment.avatarUrl"
                class="border-round-xl"
                style="height: 50px; width: 50px"
              />
              <i
                class="pi pi-ellipsis-v font-bold text-2xl cursor-pointer"
                (click)="showAppointmentDetails(appointment)"
              ></i>
            </div>
            <div class="flex flex-column justify-content-center gap-1">
              <span>{{ appointment.fullName }}</span>
              <span
                ><i class="pi pi-calendar mr-2"></i
                >{{ appointment.appointmentDate | date }}</span
              >
              <span
                ><i class="pi pi-clock mr-2"></i
                >{{ appointment.appointmentTimeFormatted }}</span
              >
              <div
                class="flex xl:flex-row flex-column xl:justify-content-between gap-2"
              >
                <span class="font-bold text-sm">{{ "Απλή Επίσκεψη" }}</span>
              </div>
            </div>
            <div
              class="flex flex-row flex-wrap align-items-center justify-content-center xl:justify-content-center gap-2 mt-3"
            >
              <!-- <button
                pButton
                class="p-button-danger"
                (click)="rejectAppointment(appointment)"
              >
                Απόρριψη
              </button> -->
              <!-- <p-menu #menu [popup]="true" [model]="menuItems(appointment)"></p-menu>
              <button type="button" pButton icon="pi pi-chevron-down" label="Επεξεργασία" (click)="menu.toggle($event)" style="width: auto"></button>
               -->

              <p-menu
                #menu
                [popup]="true"
                [model]="menuItems(selectedAppointment)"
                [appendTo]="'body'"
                [style]="{ 'z-index': '1000' }"
              ></p-menu>
              <button
                type="button"
                pButton
                icon="pi pi-chevron-down"
                label="Επεξεργασία"
                (click)="onMenuButtonClick($event, appointment)"
                style="width: auto"
              ></button>

              <button
                pButton
                class="p-button-success"
                (click)="approve(appointment)"
              >
                Αποδοχή
              </button>
            </div>
          </div>
        </div>
      </div>
      }
    </div>
    @if(appointmentData){
    <p-dialog
      [(visible)]="showAppointment"
      [breakpoints]="{ '960px': '75vw', '640px': '90vw' }"
      [style]="{ width: '75rem' }"
      [modal]="true"
      [closable]="true"
      (onHide)="view = ''"
    >
      <span class="font-bold text-2xl mb-5">{{
        appointmentData.fullName
      }}</span>
      <div class="mt-3 p-fluid">
        <div class="p-formgrid grid">
          <div class="field lg:col-6 md:col-6 col-12">
            <label class="font-bold" htmlFor="firstname">Όνομα</label>
            <input
              pInputText
              id="firstname"
              type="text"
              disabled
              [value]="appointmentData.fullName.split(' ')[0]"
            />
          </div>
          <div class="field lg:col-6 md:col-6 col-12">
            <label class="font-bold" htmlFor="lastname">Επώνυμο</label>
            <input
              pInputText
              id="lastname"
              type="text"
              disabled
              [value]="appointmentData.fullName.split(' ')[1]"
            />
          </div>
          <div class="field lg:col-6 md:col-6 col-12">
            <label class="font-bold" htmlFor="phone">Τηλέφωνο</label>
            <input
              pInputText
              id="phone"
              type="text"
              disabled
              [value]="appointmentData.mobile"
            />
          </div>
          <div class="field lg:col-6 md:col-6 col-12">
            <label class="font-bold" htmlFor="email">Email</label>
            <input
              pInputText
              id="email"
              type="text"
              disabled
              [value]="appointmentData.email"
            />
          </div>

          <div class="field col-12">
            <label class="font-bold" htmlFor="notes">Σημειώσεις</label>
            <textarea
              pInputTextarea
              id="notes"
              type="text"
              disabled
              [value]="notes"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="card">
        <h5>Ιστορικό ραντεβού</h5>
        <p-table
          [value]="getFilteredPatientHistory(appointmentData.fullName)"
          [paginator]="true"
          [rows]="10"
          [sortMode]="'multiple'"
          [tableStyle]="{ 'min-width': '60rem' }"
        >
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="appointmentDate">
                Ημερομηνία
                <p-sortIcon [field]="'appointmentDate'"></p-sortIcon>
              </th>
              <th pSortableColumn="fullName">
                Όνομα
                <p-sortIcon [field]="'fullName'"></p-sortIcon>
              </th>
              <th pSortableColumn="reasonOfVisit">
                Λόγος Επίσκεψης
                <p-sortIcon [field]="'reasonOfVisit'"></p-sortIcon>
              </th>
              <th pSortableColumn="appointmentStatus.name">
                Κατάσταση
                <p-sortIcon [field]="'appointmentStatus.name'"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-appointment>
            <tr>
              <td>{{ appointment.appointmentDate | date : "dd/MM/yyyy" }}</td>
              <td>{{ appointment.fullName }}</td>
              <td>{{ appointment.reasonOfVisit }}</td>
              <td>{{ appointment.appointmentStatus.name }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div> </p-dialog
    >}
  </div>
  }
</div>
<p-dialog
  header="Ευχαριστούμε!"
  [(visible)]="successVisible"
  [modal]="true"
  [style]="{ width: '30rem' }"
  [resizable]="false"
  [draggable]="false"
  showEffect="fade"
>
  <p class="line-height-3 m-0">
    {{ modalMessages.APPOINTMENT_SUCCESS }}
  </p>
  <ng-template pTemplate="footer">
    <button
      pButton
      (click)="successVisible = false"
      label="Κλείσιμο"
      class="p-button-outlined"
    ></button>
  </ng-template>
</p-dialog>
<p-dialog
  header="Ευχαριστούμε!"
  [(visible)]="successDeleteVisible"
  [modal]="true"
  [style]="{ width: '30rem' }"
  [resizable]="false"
  [draggable]="false"
  showEffect="fade"
>
  <p class="line-height-3 m-0">{{ modalMessages.APPOINTMENT_REJECT }}</p>
  <ng-template pTemplate="footer">
    <button
      pButton
      (click)="successDeleteVisible = false"
      label="Κλείσιμο"
      class="p-button-outlined"
    ></button>
  </ng-template>
</p-dialog>
<p-dialog
  header="Σφάλμα"
  [(visible)]="errorVisible"
  [modal]="true"
  [style]="{ width: '30rem' }"
  [resizable]="false"
  [draggable]="false"
  showEffect="fade"
>
  <p class="line-height-3 m-0">
    {{ modalMessages.APPOINTMENT_ERROR }}
  </p>
  <ng-template pTemplate="footer">
    <button
      pButton
      (click)="errorVisible = false"
      label="Κλείσιμο"
      class="p-button-outlined"
    ></button>
  </ng-template>
</p-dialog>
<p-dialog
  header="Προτεινόμενη Ημερομηνία"
  [(visible)]="editAppointmentDialog"
  [modal]="true"
  [style]="{ width: '50rem' }"
  [resizable]="false"
  [draggable]="false"
  showEffect="fade"
  [focusOnShow]="false"
>
  @if(appointmentData){
  <form #appointmentForm="ngForm" class="p-fluid">
    <div class="p-field mb-4">
      <label for="fullname">Ονοματεπώνυμο Ασθενή:</label>
      <input
        id="fullname"
        type="text"
        pInputText
        name="name"
        [ngModel]="appointmentData.fullName"
        [disabled]="true"
      />
      <div *ngIf="appointmentForm.submitted" class="p-error">
        Παρακαλώ συμπληρώστε Ονοματεπώνυμο.
      </div>
    </div>

    <div class="p-field mb-4">
      <label for="selectedDate">Επιλεγμένη Ημερομηνία:</label>
      <input
        id="PreselectedDate"
        type="text"
        pInputText
        name="PreselectedDate"
        [ngModel]="appointmentData.appointmentDate | date : 'dd/MM/yyyy'"
        [disabled]="true"
      />
    </div>

    <div class="p-field mb-4">
      <label for="selectedTime">Επιλεγμένη Ώρα:</label>
      <input
        id="PreselectedTime"
        type="text"
        pInputText
        name="PreselectedTime"
        [ngModel]="appointmentData.appointmentTime"
        [disabled]="true"
      />
    </div>

    <div class="p-field mb-4">
      <label for="selectedDate">Επιλέξτε Προτεινόμενη Ημερομηνία:</label>

      <p-calendar
        name="selectedDate"
        id="selectedDate"
        [(ngModel)]="availableDay"
        [inline]="false"
        dateFormat="dd/mm/yy"
        placeholder="30/8/2026"
        [iconDisplay]="'input'"
        [showIcon]="true"
        selectionMode="single"
        [readonlyInput]="true"
        required
        appendTo="body"
        readonly
      >
      </p-calendar>
    </div>
    <div class="p-field mb-4">
      <label for="selectedTime">Επιλέξτε Προτεινόμενη Ώρα:</label>
      <p-multiSelect
        id="selectedTime"
        [options]="availableTimes"
        [(ngModel)]="selectedTime"
        placeholder=""
        optionLabel="name"
        name="selectedTime"
        placeholder="19:00"
        display="chip"
        [showClear]="true"
        appendTo="body"
      />
    </div>
    <div class="p-field mb-4">
      <label for="notes">Σημειώσεις:</label>
      <textarea
        rows="5"
        cols="30"
        pInputTextarea
        [autoResize]="false"
      ></textarea>
    </div>
  </form>
  }
  <ng-template pTemplate="footer">
    <button
      pButton
      (click)="editAppointmentDialog = false"
      label="Κλείσιμο"
      class="p-button-outlined"
    ></button>
    <button
      pButton
      [disabled]="false"
      type="submit"
      label="Αίτημα"
      class="p-button submit-btn"
    ></button>
  </ng-template>
</p-dialog>
