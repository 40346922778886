import { DatePipe, CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { Subscription } from 'rxjs';
import { Appointment } from '../../../interfaces/appointment';
import { ModalMessage } from '../../../enums/modal-messages.constants';
import { AppointmentsService } from '../../../services/appointment/appointments.service';
import { AppointmentStatus } from '../../../enums/appointment-status.constants';
// import { Appointment, ModalMessage, AppointmentsService, AppointmentStatus } from 'shared';

@Component({
  selector: 'lib-pending-appointments',
  standalone: true,
  imports: [
    DatePipe,
    CalendarModule,
    MenuModule,
    DialogModule,
    FormsModule,
    CommonModule,
    TableModule,
    MultiSelectModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
  ],
  templateUrl: './pending-appointments.component.html',
  styleUrl: './pending-appointments.component.css',
})
export class PendingAppointmentsComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() appointments!: any[];
  @Input() confirmed_patient_history: any[] = [];

  @Output() changedAppointments = new EventEmitter();
  @Output() appointmentsChange = new EventEmitter<any[]>();
  showAppointment: boolean = false;
  editAppointmentDialog: boolean = false;
  appointmentData!: Appointment;
  availableDay: any;

  notes: any = 'Επείγον';
  menuItems = [
    {
      label: 'Επεξεργασία',
      icon: 'pi pi-fw pi-pencil',
      command: () => {
        this.editAppointmentDialog = true;
      },
    },
    {
      label: 'Απόρριψη',
      icon: 'pi pi-fw pi-times',
      command: () => this.reject(this.appointmentData),
    },
  ];

  availableTimes: any;
  selectedTime: any;
  selectedAppointment: any = null;

  successVisible: boolean = false;
  successDeleteVisible: boolean = false;
  errorVisible: boolean = false;
  editAppointmentDateBtn: boolean = false;

  modalMessages = ModalMessage;

  appointmentService = inject(AppointmentsService);

  subscriptions = new Subscription();
  ngOnInit(): void {
    this.availableTimes = this.getTimesAsObjects();
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes);
  }
  getTimesAsObjects(): { name: string; code: string }[] {
    const times: { name: string; code: string }[] = [];
    const startHour = 9;
    const endHour = 21;

    for (let hour = startHour; hour <= endHour; hour++) {
      const formattedTime = `${hour.toString().padStart(2, '0')}:00`;
      const timeCode = hour.toString().padStart(2, '0'); // Using the hour as code

      times.push({ name: formattedTime, code: timeCode });
    }
    return times;
  }

  showAppointmentDetails(appointment: any) {
    this.showAppointment = true;
    this.appointmentData = appointment;
  }

  editAppointment(appointment: any) {
    this.appointmentData = appointment;
    this.editAppointmentDialog = true;
  }

  reject(appointment: any) {
    let appointmentStatus = {
      appointmentStatusId: 2,
    };
    const appointmentService$ = this.appointmentService
      .updateAppointmentStatusById(appointment.appointmentId, appointmentStatus)
      .subscribe({
        next: (res) => {
          appointment.appointmentStatus.name = 'Απορρίφθηκε';
          this.appointments = this.appointments.filter(
            (app) => app.appointmentId != appointment.appointmentId
          );
          this.changedAppointments.emit(appointment);
          this.successDeleteVisible = true;
        },
        error: (error: any) => {
          this.errorVisible = true;
          console.error('Error approving appointment:', error);
        },
        complete: () => {},
      });
    this.subscriptions.add(appointmentService$);
  }

  approve(appointment: any) {
    let appointmentStatus = {
      appointmentStatusId: 1,
    };
    this.subscriptions.add(
      this.appointmentService
        .updateAppointmentStatusById(
          appointment.appointmentId,
          appointmentStatus
        )
        .subscribe({
          next: (res) => {
            appointment.appointmentStatus.name = AppointmentStatus.APPROVED;
            appointment.appointmentStatus.appointmentStatusId = 1;

            this.appointments = this.appointments.filter(
              (app) => app.appointmentId != appointment.appointmentId
            );
            this.successVisible = true;
            this.changedAppointments.emit(appointment);
          },
          error: (error: any) => {
            this.errorVisible = true;
            console.error('Error approving appointment:', error);
          },
          complete: () => {},
        })
    );
  }

  getFilteredPatientHistory(fullName: string) {
    if (!fullName) {
      return [];
    }
    return this.confirmed_patient_history.filter(
      (appointment) => appointment.fullName === fullName
    );
  }

  changeAppointmentDate() {
    if (this.availableDay && this.selectedTime) {
      const formattedDate = this.formatDate(this.availableDay); // formats to yyyy-mm-dd
      const appointmentDate = {
        date: formattedDate,
        time: `${this.selectedTime[0].name}:00`,
      };
      const updateAppointmentDateSubscription$ = this.appointmentService
        .updateAppointmentDateById(
          this.appointmentData.appointmentId,
          appointmentDate
        )
        .subscribe({
          next: () => {
            console.log(this.appointmentData);
            this.appointmentData.appointmentDate = formattedDate;
            this.appointmentData.appointmentTime = `${this.selectedTime[0].name}:00`;
            this.appointmentsChange.emit(this.appointments);
          },
          error(err) {},
          complete: () => {
            this.successVisible = true;
            this.editAppointmentDialog = false;
          },
        });
    }
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
}
