@if(dataLoaded){
<lib-base-dashboard [kpiCards]="kpis()">
  <div class="lg:col-6 col-12">
    <div class="card h-full">
      <app-dashboard-charts chartCase="monthlyChart"></app-dashboard-charts>
    </div>
  </div>
  <div class="lg:col-6 col-12">
    <div class="card h-full">
      <h5>Λίστα εισπράξεων</h5>
      <p-table
        [value]="collection"
        responsiveLayout="scroll"
        [paginator]="true"
        [rows]="5"
        [tableStyle]="{ 'min-width': '50rem' }"
      >
        <ng-template pTemplate="header">
          <tr>
            <th>Πελάτης</th>
            <th>Είσπραξη</th>
            <th>Υπόλοιπο</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-record>
          <tr>
            <td>{{ record.customer }}</td>
            <td>{{ record.receipt | currency : "EUR" }}</td>
            <td>{{ record.balance | currency : "EUR" }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <div class="lg:col-12 col-12">
    <div class="card">
      <h5>Λίστα τελευταίων συναλλαγών</h5>
      <p-table
        [value]="records"
        responsiveLayout="scroll"
        [paginator]="true"
        [rows]="5"
        [tableStyle]="{ 'min-width': '50rem' }"
      >
        <ng-template pTemplate="header">
          <tr>
            <th>Ασθενής</th>
            <th>Ημερομηνία</th>
            <th>Χρέωση</th>
            <th>Πίστωση</th>
            <th>Αιτιολογία</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-record>
          <tr>
            <td>{{ record.patient }}</td>
            <td>{{ record.date | date : "dd/MM/yyyy" }}</td>
            <td>{{ record.charge | currency : "EUR" }}</td>
            <td>{{ record.credit | currency : "EUR" }}</td>
            <td>{{ record.description }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</lib-base-dashboard>
}@else {
<div
  class="col-12 flex justify-content-center align-items-center h-100em w-100em"
>
  <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
</div>

}
