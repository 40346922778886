import { Component, OnDestroy, OnInit } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {
  AppointmentsService,
  AppointmentsTableComponent,
  AuthService,
  CustomCalendarComponent,
  PendingAppointmentsComponent,
} from 'shared';
import { FullCalendarModule } from '@fullcalendar/angular';

import { forkJoin, Subscription, switchMap } from 'rxjs';
import { TabViewModule } from 'primeng/tabview';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { ActivatedRoute } from '@angular/router';
import { BadgeModule } from 'primeng/badge';
import { CalendarModule } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { CommonModule } from '@angular/common';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { TagModule } from 'primeng/tag';
import { RippleModule } from 'primeng/ripple';
import { MenuItem } from 'primeng/api';
import { MenubarModule } from 'primeng/menubar';
import { InputTextModule } from 'primeng/inputtext';
import { Menu, MenuModule } from 'primeng/menu';
// import { PendingAppointmentsComponent } from './pending-appointments/pending-appointments.component';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  standalone: true,
  imports: [
    TabViewModule,
    DialogModule,
    FullCalendarModule,
    BadgeModule,
    CalendarModule,
    ButtonModule,
    FormsModule,
    MultiSelectModule,
    TableModule,
    InputTextareaModule,
    DropdownModule,
    TagModule,
    RippleModule,
    CommonModule,
    MenubarModule,
    InputTextModule,
    MenuModule,
    PendingAppointmentsComponent,
    AppointmentsTableComponent,
    CustomCalendarComponent,
  ],
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, OnDestroy {
  tags: any;
  showDialog: boolean = false;
  clickedEvent: any = null;
  view: string = '';
  calendarOptions: any = {
    plugins: [dayGridPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    dateClick: this.onDateSelect.bind(this),
    weekends: false,
  };
  tableHeaders = [
    { field: 'socialSecurityNumber', header: 'ΑΜΚΑ' },
    { field: 'fullName', header: 'Όνοματεπώνυμο' },
    { field: 'appointmentDate', header: 'Ημερομηνία' },
    { field: 'appointmentTime', header: 'Ώρα' },
    { field: 'reasonOfVisit', header: 'Τύπος' },
    { field: 'notes', header: 'Σημειώσεις' },
  ];

  changedEvent: any;
  patient_history: any[] = [];
  confirmed_patient_history: any[] = [];
  pendingAppointments: any[] = [];
  subscriptions = new Subscription();

  appointments: any[] = [];

  currentDate = new Date();

  items: MenuItem[] = [];
  selectedMenuItem: string = 'appointments';
  // menuItems: MenuItem[] = [];
  selectedAppointment: any = null;
  menu!: Menu;

  constructor(
    private appointmentService: AppointmentsService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.fetchData();
    this.getCalendarNavigationItems();
    this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment == 'pendingappointments') {
        // url + /calendar#pendingappointments
        this.selectedMenuItem = 'pendingappointments';
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  private fetchData() {
    const doctorSubscription$ = this.authService.currentUser$
      .pipe(
        switchMap((doctor) => {
          if (doctor) {
            return forkJoin([this.fetchAppointments(doctor.data.userId)]);
          }
          return [];
        })
      )
      .subscribe({
        next: ([appointmentResult]) =>
          this.handleAppointmentResult(appointmentResult),
        error: (err) => {}, // console.error('Error fetching appointments:', err),
      });

    this.subscriptions.add(doctorSubscription$);
  }

  private fetchAppointments(userId: number) {
    return this.appointmentService.getAppointments(userId);
  }

  private handleAppointmentResult(appointmentResult: any): void {
    if (!appointmentResult) return;

    this.patient_history = appointmentResult.data;
    this.appointments = this.patient_history;
    this.formatAppointments(appointmentResult.data);

    this.handleAppointments();
    this.getCalendarOptions(this.appointments);
    // console.log(this.appointments);
  }

  private formatAppointments(data: any) {
    data.map((appointment: any) => {
      this.getAppointmentStatus(appointment);
    });
  }
  private getAppointmentStatus(appointment: any) {
    let appDate = new Date(appointment.appointmentDate);
    if (
      this.currentDate > appDate &&
      (appointment.appointmentStatus.appointmentStatusId == 3 ||
        appointment.appointmentStatus.appointmentStatusId == 1)
    ) {
      appointment.appointmentStatus = {
        appointmentStatusId: 2,
        name: 'Ακυρωμένο',
      };
    }
    return appointment.appointmentStatus;
  }
  private handleAppointments() {
    this.appointments.sort((a: any, b: any) => {
      const dateA = new Date(a.appointmentDate).getTime();
      const dateB = new Date(b.appointmentDate).getTime();
      return dateB - dateA;
    });
    this.pendingAppointments = this.appointments.filter((item: any) => {
      return (
        item.appointmentStatus.appointmentStatusId == 3 ||
        item.appointmentStatus.name == 'Εκκρεμεί'
      );
    });
    this.confirmed_patient_history = this.appointments.filter((item: any) => {
      return (
        item.appointmentStatus.appointmentStatusId == 5 ||
        item.appointmentStatus.name == 'Ολοκληρωμένο'
      );
    });
  }
  private getCalendarOptions(data: any) {
    this.calendarOptions = {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin], // Ensure plugins are included
      height: 720,
      initialView: 'dayGridMonth',
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay',
      },
      editable: true,
      selectable: false,
      selectMirror: true,
      dayMaxEvents: true,
      eventClick: (e: MouseEvent) => this.onEventClick(e),
      select: (e: MouseEvent) => this.onDateSelect(e),

      events: data.map((appointment: any) => {
        // Generate a random time for the appointment
        // Parse the appointment start time
        const startDateTime = new Date(
          `${appointment.appointmentDate}T${appointment.appointmentTime}`
        );

        // Clone the date object to create the end time, and add one hour
        const endDateTime = new Date(startDateTime);
        endDateTime.setHours(startDateTime.getHours() + 1);
        let color;

        // Determine color and status based on appointment status
        switch (appointment.appointmentStatus.appointmentStatusId) {
          case 1:
            color = '#17a2b8'; // Yellow for confirmed
            status = 'info';
            break;
          case 2:
            color = '#dc3545'; // Red for rejected
            status = 'danger';
            break;
          case 3:
            color = '#ffc107'; // Blue for pending
            status = 'warning';
            break;
          case 4:
            color = '#dc3545'; // Grey for canceled
            status = 'danger';
            break;
          case 5:
            color = '#28a745'; // Green for completed
            status = 'success';
            break;
          default:
            color = '#dc3545'; // Red for any other unrecognized status
            status = 'danger';
            break;
        }

        // Return event object with computed properties
        return {
          title: appointment.fullName,
          start: startDateTime.toISOString(), // or keep as `${appointment.appointmentDate}T${appointment.appointmentTime}`
          end: endDateTime.toISOString(),
          description: appointment.reasonOfVisit,
          color: color,
        };
      }),
    };
  }

  getSelectedTime(event: any) {
    const time = event;
  }
  getPendingAppointments(event: any) {
    // gets new value from children data emittion
    this.appointments = this.appointments.map((app) => {
      if (app.appointmentId === event.appointmentId) {
        return event;
      }
      return app;
    });
  }

  onEditClick() {}
  onEventClick(e: any) {
    this.clickedEvent = e.event;
    let plainEvent = e.event.toPlainObject({
      collapseExtendedProps: true,
      collapseColor: true,
    });
    this.view = 'display';
    this.showDialog = true;

    this.changedEvent = { ...plainEvent, ...this.clickedEvent };
    this.changedEvent.start = this.clickedEvent.start;
    this.changedEvent.end = this.clickedEvent.end
      ? this.clickedEvent.end
      : this.clickedEvent.start;
  }

  onDateSelect(e: any) {
    this.view = 'new';
    this.showDialog = true;
    this.changedEvent = {
      ...e,
      title: null,
      description: null,
      location: null,
      backgroundColor: null,
      borderColor: null,
      textColor: null,
      tag: { color: null, name: null },
    };
  }

  // Helper function to format the date to match the 'appointmentDate' format
  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  selectMenuItem(item: any): void {
    this.selectedMenuItem = item;
    // Update the styleClass for each menu item based on selection
    this.items = this.items.map((menuItem) => ({
      ...menuItem,
      styleClass:
        menuItem.label === this.getLabelByItemKey(item)
          ? 'active-menu-item'
          : '',
    }));
  }

  // Helper method to get the label based on the selected key
  getLabelByItemKey(key: string): string {
    const map: { [key: string]: string } = {
      calendar: 'Ημερολόγιο',
      appointments: 'Ραντεβού',
      pendingappointments: 'Ραντεβού σε εκκρεμότητα',
    };
    return map[key] || '';
  }

  getCalendarNavigationItems() {
    this.items = [
      {
        label: 'Ημερολόγιο',
        icon: '',
        command: () => this.selectMenuItem('calendar'),
        styleClass:
          this.selectedMenuItem === 'calendar' ? 'active-menu-item' : '',
      },
      {
        label: 'Ραντεβού',
        icon: '',
        command: () => this.selectMenuItem('appointments'),
        styleClass:
          this.selectedMenuItem === 'appointments' ? 'active-menu-item' : '',
      },
      {
        label: 'Εκκρεμή Ραντεβού',
        icon: '',
        command: () => this.selectMenuItem('pendingappointments'),
        styleClass:
          this.selectedMenuItem == 'pendingappointments'
            ? 'active-menu-item'
            : '',
      },
    ];
  }
}
