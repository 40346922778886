import { Component, OnInit } from '@angular/core';
import { TabViewModule } from 'primeng/tabview';
import { ImportsModule } from 'shared';

@Component({
  selector: 'app-prescription-referral',
  standalone: true,
  imports: [ImportsModule, TabViewModule],
  templateUrl: './prescription-referral.component.html',
  styleUrl: './prescription-referral.component.scss',
})
export class PrescriptionReferralComponent implements OnInit {
  prescriptions = [
    {
      patient: 'Γεώργιος Παπαδόπουλος',
      doctor: 'Δρ. Ιωάννης Κωνσταντινίδης',
      prescriptionDate: new Date('2024-08-01'),
      expirationDate: new Date('2024-08-31'),
      medication: 'Παρακεταμόλη 500mg',
      quantity: '30 δισκία',
      cost: '15.00 €',
    },
    {
      patient: 'Μαρία Καραγιάννη',
      doctor: 'Δρ. Αλέξανδρος Δημητρίου',
      prescriptionDate: new Date('2024-07-15'),
      expirationDate: new Date('2024-08-14'),
      medication: 'Αμοξικιλλίνη 500mg',
      quantity: '20 κάψουλες',
      cost: '25.00 €',
    },
    {
      patient: 'Δημήτρης Σπυρόπουλος',
      doctor: 'Δρ. Ελένη Παπαδοπούλου',
      prescriptionDate: new Date('2024-06-10'),
      expirationDate: new Date('2024-07-10'),
      medication: 'Ατορβαστατίνη 10mg',
      quantity: '60 δισκία',
      cost: '45.00 €',
    },
    {
      patient: 'Αναστασία Λάμπρου',
      doctor: 'Δρ. Σταύρος Μανουσάκης',
      prescriptionDate: new Date('2024-05-22'),
      expirationDate: new Date('2024-06-21'),
      medication: 'Ομεπραζόλη 20mg',
      quantity: '28 κάψουλες',
      cost: '20.00 €',
    },
  ];
  history = [
    {
      patient: 'Γεώργιος Παπαδόπουλος',
      doctor: 'Δρ. Ιωάννης Κωνσταντινίδης',
      prescriptionDate: new Date('2024-08-01'),
      expirationDate: new Date('2024-08-31'),
      examinationType: 'Γενική Αίματος',
      notes: 'Αναμονή αποτελεσμάτων',
      cost: '30.00 €',
    },
    {
      patient: 'Μαρία Καραγιάννη',
      doctor: 'Δρ. Αλέξανδρος Δημητρίου',
      prescriptionDate: new Date('2024-07-15'),
      expirationDate: new Date('2024-08-14'),
      examinationType: 'Ακτινογραφία Θώρακος',
      notes: 'Χρειάζεται επιπλέον αξιολόγηση',
      cost: '50.00 €',
    },
    {
      patient: 'Δημήτρης Σπυρόπουλος',
      doctor: 'Δρ. Ελένη Παπαδοπούλου',
      prescriptionDate: new Date('2024-06-10'),
      expirationDate: new Date('2024-07-10'),
      examinationType: 'Υπέρηχος Κοιλιάς',
      notes: 'Εξετάστε για πιθανές ανωμαλίες',
      cost: '60.00 €',
    },
    {
      patient: 'Αναστασία Λάμπρου',
      doctor: 'Δρ. Σταύρος Μανουσάκης',
      prescriptionDate: new Date('2024-05-22'),
      expirationDate: new Date('2024-06-21'),
      examinationType: 'Νευρολογική Εκτίμηση',
      notes: 'Επαναληπτική εξέταση σε 3 μήνες',
      cost: '75.00 €',
    },
    {
      patient: 'Ελένη Μακρίδη',
      doctor: 'Δρ. Μαρία Σταυρίδου',
      prescriptionDate: new Date('2024-04-10'),
      expirationDate: new Date('2024-05-10'),
      examinationType: 'Μαγνητική Τομογραφία',
      notes: 'Ειδική εξέταση για σπονδυλική στήλη',
      cost: '120.00€',
    },
  ];
  constructor() {}
  ngOnInit(): void {}
}
