import { Component, OnInit, inject } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import {
  AuthService,
  ChatService,
  MemberService,
  MenuDataService,
} from 'shared';
import { RouterOutlet } from '@angular/router';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
  private primengConfig = inject(PrimeNGConfig);
  private menuDataService = inject(MenuDataService);
  private memberService = inject(MemberService);
  private authService = inject(AuthService);
  private chatService = inject(ChatService);

  model: any = [
    {
      label: '',
      icon: 'pi pi-fw pi-sitemap',
      items: [
        {
          label: 'Πίνακας Ελέγχου',
          icon: 'pi pi-fw pi-home',
          routerLink: [''],
        },
        {
          label: 'Το Ημερολόγιο μου',
          icon: 'pi pi-fw pi-calendar',
          routerLink: ['/calendar'],
        },
        {
          label: 'Βάση Ασθενών',
          icon: 'pi pi-fw pi-users',
          routerLink: ['/manage-patients'],
        },
        {
          label: 'Συνταγογράφηση',
          icon: 'pi pi-fw pi-heart',
          routerLink: ['/prescriptions'],
        },

        {
          label: 'Σύνθεση Ιατρείου',
          icon: 'pi pi-fw pi-warehouse',
          routerLink: ['***'],
        },
      ],
    },
  ];

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.menuDataService.update(this.model);
    this.memberService.appName = 'doctor';
    const user = JSON.parse(localStorage.getItem('token')!);
    if (user) {
      this.authService.fetchUser(user).subscribe({
        next: () => {
          this.chatService.createCurrentSession();
        },
        error: () => {
          this.authService.logout();
        },
      });
    }
  }
}
