import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  signal,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TabViewModule } from 'primeng/tabview';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { ListboxModule } from 'primeng/listbox';
import { ChipModule } from 'primeng/chip';
import { catchError, forkJoin, of, Subscription, switchMap, tap } from 'rxjs';
import {
  DoctorService,
  ExamsService,
  ImportsModule,
  MemberService,
  ModalMessage,
} from 'shared';
import { DropdownChangeEvent } from 'primeng/dropdown';

@Component({
  selector: 'app-patient-details',
  standalone: true,
  imports: [
    ImportsModule,
    TabViewModule,
    FileUploadModule,
    ToastModule,
    ListboxModule,
    ChipModule,
  ],
  templateUrl: './patient-details.component.html',
  styleUrl: './patient-details.component.scss',
})
export class PatientDetailsComponent implements OnInit, OnDestroy {
  diagnoses: any = [
    {
      icd10: 'A00',
      diagnosis: 'Χολέρα',
      comment: 'Χωρίς πρόσφατο ιστορικό ταξιδιού',
      event: 'Ξέσπασμα',
      startDate: new Date('2024-01-10'),
      outcome: 'Ανάρρωση',
      endDate: new Date('2024-01-20'),
    },
    {
      icd10: 'B20',
      diagnosis: 'Νόσος του HIV',
      comment: 'Υπό θεραπεία ART',
      event: 'Προγραμματισμένος έλεγχος',
      startDate: new Date('2023-12-05'),
      outcome: 'Σταθερή',
      endDate: new Date('2024-01-15'),
    },
  ];
  records = [
    {
      date: new Date('2024-08-20'),
      title: 'Εξετάσεις αίματος',
      category: 'Δια ζώσης',
      reason: 'Ρουτίνα',
      notes: 'Αναμονή αποτελεσμάτων',
    },
    {
      date: new Date('2024-07-15'),
      title: 'Συνάντηση με γιατρό',
      category: 'Βιντεοκλήση',
      reason: 'Ανασκόπηση φαρμακευτικής αγωγής',
      notes: 'Συνεχίστε με την ίδια αγωγή',
    },
    {
      date: new Date('2024-06-10'),
      title: 'Διαγνωστικές εξετάσεις',
      category: 'Δια ζώσης',
      reason: 'Συμπτώματα άσθματος',
      notes: 'Χρειάζεται περαιτέρω αξιολόγηση',
    },
    {
      date: new Date('2024-05-22'),
      title: 'Ψυχιατρική συνεδρία',
      category: 'Βιντεοκλήση',
      reason: 'Παρακολούθηση',
      notes: 'Αναφορά βελτίωσης',
    },
  ];
  examinations = [
    {
      date: new Date('2024-08-20'),
      category: 'Αιματολογικές',
      examination: 'Ολική Αιματολογική Εξέταση',
      result: 'Κανονικά επίπεδα',
    },
    {
      date: new Date('2024-07-15'),
      category: 'Ακτινολογικές',
      examination: 'Ακτινογραφία Θώρακος',
      result: 'Χωρίς ευρήματα',
    },
    {
      date: new Date('2024-06-10'),
      category: 'Βιοχημικές',
      examination: 'Εξέταση Γλυκόζης',
      result: 'Ελαφρώς αυξημένη',
    },
    {
      date: new Date('2024-05-22'),
      category: 'Ουρολογικές',
      examination: 'Ανάλυση Ούρων',
      result: 'Χωρίς ανωμαλίες',
    },
  ];

  files: any = [
    {
      fileName: 'Patient_Record_01.pdf',
      dateOfUpload: new Date('2024-08-01'),
      fileSize: '1.5 MB',
    },
    {
      fileName: 'XRay_Image_02.png',
      dateOfUpload: new Date('2024-07-28'),
      fileSize: '2.3 MB',
    },
    {
      fileName: 'Blood_Test_Report_03.docx',
      dateOfUpload: new Date('2024-07-15'),
      fileSize: '250 KB',
    },
    {
      fileName: 'MRI_Scan_04.jpg',
      dateOfUpload: new Date('2024-06-30'),
      fileSize: '5.6 MB',
    },
    {
      fileName: 'Prescription_05.pdf',
      dateOfUpload: new Date('2024-05-22'),
      fileSize: '800 KB',
    },
  ];

  exams: any;
  selectedExams: any;
  icd10: string = '';
  selectedIcd10: any = '';
  textAreaValue: string = '';
  modalMessage: any;
  modalMessages = ModalMessage;
  insured: any;
  clinicalExams: any;

  showHospitalizationDialog = false;

  selectedDateForAppointment: any;

  subscriptions: Subscription[] = [];
  name: any;
  uploadedFiles: any[] = [];

  showPrescription: boolean = false;
  showReference: boolean = false;
  doctorName: any;
  dataLoaded: boolean = false;
  successVisible: boolean = false;
  errorVisible: boolean = false;
  requestAccessDialog: boolean = false;
  requestAccessDisabled: boolean = false;
  @ViewChildren('buttonEl') buttonEl!: QueryList<ElementRef>;

  userId: number = 0;
  doctor: any;
  selectedSubCategory = null;
  selectedCategory = signal(null);

  examCategories: any[] = [];
  examSubCategories: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private examService: ExamsService,
    private doctorService: DoctorService,
    private patientService: MemberService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}
  ngOnInit(): void {
    this.userId = Number(this.route.snapshot.paramMap.get('id'));
    this.doctorName = JSON.parse(localStorage.getItem('token')!);
    this.fetchData();
    this.doctorName.userName = this.doctorName.userName
      .split('_')
      .map((namePart: any) => {
        return namePart.charAt(0).toUpperCase() + namePart.slice(1);
      })
      .join(' ');
  }
  fetchData() {
    this.doctorService
      .getDoctorDataByUsername(this.doctorName.userName)
      .pipe(
        tap((doctor) => {
          if (!doctor) throw new Error('Doctor not found');
          this.doctor = doctor;
        }),

        switchMap(() =>
          forkJoin({
            patientDetails: this.patientService
              .getMemberDetailsById(this.userId)
              .pipe(catchError((err) => of({ data: [] }))),
            clinicalExams: this.patientService
              .getInsuredClinicalExamsByMemberId(this.userId)
              .pipe(catchError((err) => of({ data: [] }))),
            examCategories: this.examService
              .getExamCategories()
              .pipe(catchError(() => of(null))),
          })
        )
      )
      .subscribe({
        next: ({ patientDetails, clinicalExams, examCategories }) => {
          if (patientDetails) {
            this.insured = patientDetails.data;
          }

          if (clinicalExams) {
            this.clinicalExams = clinicalExams.data;
          }

          if (examCategories) {
            this.examCategories = examCategories.map((exam: any) => ({
              name: exam.serviceCategoryDescription,
              code: exam.serviceCategoryCode,
            }));
          }
        },
        error: (error) => {
          console.error('Error fetching data:', error);
        },
        complete: () => {
          this.dataLoaded = true;
          console.log('All data loaded successfully');
        },
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.pop();
  }

  onUpload(event: any) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
  }

  onImageMouseOver(file: any) {
    this.buttonEl.toArray().forEach((el) => {
      el.nativeElement.id === file.name
        ? (el.nativeElement.style.display = 'flex')
        : null;
    });
  }

  onImageMouseLeave(file: any) {
    this.buttonEl.toArray().forEach((el) => {
      el.nativeElement.id === file.name
        ? (el.nativeElement.style.display = 'none')
        : null;
    });
  }

  removeImage(event: Event, file: any) {
    event.stopPropagation();
    this.uploadedFiles = this.uploadedFiles.filter((i) => i !== file);
  }

  showAddPrescription() {
    this.showPrescription = true;
  }

  formatDate(date: any): string {
    const [day, month, year] = date.split('/');

    return `${day}`;
  }

  formatTime(timeStr: string): string {
    let [time, period] = timeStr.split(' ');
    let [hours, minutes] = time.split(':').map(Number);

    if (period === 'PM' && hours < 12) {
      hours += 12;
    }
    if (period === 'AM' && hours === 12) {
      hours = 0;
    }

    let formattedHours = hours.toString().padStart(2, '0');
    let formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:00`;
  }

  isCode(input: string): boolean {
    const codePattern = /^[A-Za-z]\d{1,2}(\.\d+)?$/;
    return codePattern.test(input);
  }
  onCategoryChange(event: DropdownChangeEvent) {
    console.log(event);
    this.selectedCategory.set(event.value);
    this.selectedSubCategory = null;
    this.exams = [];
    this.examSubCategories = [];
    this.selectedExams = null;
    this.examService.getExamSubcategories(event.value.code).subscribe({
      next: (subCats) => {
        console.log(subCats);
        this.examSubCategories = subCats.map((exam: any) => ({
          name: exam.serviceSubcategoryDescription,
          code: exam.serviceSubcategoryCode,
        }));
      },
    });
  }
  onSubCategoryChange(event: DropdownChangeEvent) {
    this.selectedSubCategory = event.value;
    this.examService.getExams(event.value.code).subscribe({
      next: (subCats) => {
        console.log(subCats);
        this.exams = subCats.map((exam: any) => ({
          name: exam.serviceDescriptionGr,
          code: exam.serviceCode,
        }));
      },
    });
  }

  searchIcd10() {
    if (this.isCode(this.icd10)) {
      this.examService
        .getICD10ByNameOrCode(undefined, this.icd10)
        .subscribe((res) => {
          this.selectedIcd10 = res.data;
        });
    } else {
      this.examService
        .getICD10ByNameOrCode(this.icd10, undefined)
        .subscribe((res) => {
          this.selectedIcd10 = res.data;
        });
    }
  }

  addReference() {
    if (
      this.selectedIcd10 &&
      (this.selectedExams || this.selectedExams.length > 0)
    ) {
      let reference: any = {
        userId: this.userId,
        requestUserId: this.doctor.data.userId,
        startDate: this.calculateDate(0),
        expiryDate: this.calculateDate(2),
        icd10id: this.selectedIcd10[0].icd10Id,
        examTypes: this.selectedExams.map((item: any) => {
          return item.code;
        }),
        notes: this.textAreaValue,
      };
      console.log(this.selectedExams);
      const createExamSubscription$ = this.examService
        .createExams(reference)
        .subscribe({
          next: (res) => {
            this.icd10 = '';
            this.selectedIcd10 = '';
            this.successVisible = true;
            this.showReference = false;
          },
          error: (err) => {
            console.error(err);
            this.showReference = false;
            this.errorVisible = true;
          },
          complete: () => {
            /*checks if the validation message from the api is null,
             which means no validation errors and closes the modal */
          },
        });

      this.subscriptions.push(createExamSubscription$);
    }
  }

  addHospitalization(event: any) {
    const button = event.target as HTMLButtonElement;
    button.disabled = true;
    console.log(event);

    setTimeout(() => {
      this.successVisible = true;
      this.showHospitalizationDialog = false;
    }, 1000);
  }

  calculateDate(number: number): string {
    const date = new Date();
    date.setMonth(date.getMonth() + number);

    if (date.getDate() < new Date().getDate()) {
      date.setDate(0);
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  formValidator(message: any) {
    if (message.validationMessages)
      return message.validationMessages.map((m: any) => {
        return m;
      });
    else {
      return '';
    }
  }

  addMedicine() {
    const parent = this.el.nativeElement.querySelector('#medicines');

    const newRow = this.renderer.createElement('div');
    this.renderer.addClass(newRow, 'flex');
    this.renderer.addClass(newRow, 'gap-5');
    this.renderer.addClass(newRow, 'align-items-center');

    const medicineDiv = this.renderer.createElement('div');
    this.renderer.addClass(medicineDiv, 'flex');
    this.renderer.addClass(medicineDiv, 'flex-column');
    this.renderer.addClass(medicineDiv, 'gap-2');

    const medicineLabel = this.renderer.createElement('label');
    this.renderer.setAttribute(medicineLabel, 'for', 'medicineInput');
    this.renderer.addClass(medicineLabel, 'font-semibold');
    const medicineLabelText = this.renderer.createText('Φάρμακο');
    this.renderer.appendChild(medicineLabel, medicineLabelText);

    const medicineInput = this.renderer.createElement('input');
    this.renderer.setAttribute(medicineInput, 'pInputText', '');
    this.renderer.setAttribute(medicineInput, 'autocomplete', 'off');
    this.renderer.addClass(medicineInput, 'flex-auto');
    this.renderer.addClass(medicineInput, 'p-inputtext');

    this.renderer.appendChild(medicineDiv, medicineLabel);
    this.renderer.appendChild(medicineDiv, medicineInput);

    const quantityDiv = this.renderer.createElement('div');
    this.renderer.addClass(quantityDiv, 'flex');
    this.renderer.addClass(quantityDiv, 'flex-column');
    this.renderer.addClass(quantityDiv, 'gap-2');

    const quantityLabel = this.renderer.createElement('label');
    this.renderer.setAttribute(quantityLabel, 'for', 'quantityInput');
    this.renderer.addClass(quantityLabel, 'font-semibold');
    const quantityLabelText = this.renderer.createText('Ποσότητα');
    this.renderer.appendChild(quantityLabel, quantityLabelText);

    const quantityInput = this.renderer.createElement('input');
    this.renderer.setAttribute(quantityInput, 'pInputText', '');
    this.renderer.setAttribute(quantityInput, 'autocomplete', 'off');
    this.renderer.addClass(quantityInput, 'flex-auto');
    this.renderer.addClass(quantityInput, 'p-inputtext');

    this.renderer.appendChild(quantityDiv, quantityLabel);
    this.renderer.appendChild(quantityDiv, quantityInput);

    this.renderer.appendChild(newRow, medicineDiv);
    this.renderer.appendChild(newRow, quantityDiv);

    this.renderer.appendChild(parent, newRow);
  }

  showAddReference() {
    this.showReference = true;
  }
}
