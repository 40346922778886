<div class="surface-0">
  <div
    class="flex align-items-center justify-content-between flex-column h-screen"
  >
    <div
      class="flex flex-column align-items-center justify-content-center w-full md:w-4 h-full text-center py-6 px-4"
    >
      <a [routerLink]="['/']" class="mb-6">
        <!-- <img src="../../assets/layout/images/phi-logo.png" class="w-16rem"> -->
        <img
          src="../../assets/layout/images/phi_eurolife.png"
          class="w-10rem"
        />
      </a>
      <!-- <div class="mb-4">
                <div class="text-900 text-xl font-bold mb-2">Register</div>
                <span class="text-600 font-medium">Let's get started</span>
            </div> -->
      <div class="flex flex-column">
        <span class="p-input-icon-left w-full mb-4">
          <i class="pi pi-user"></i>
          <input
            id="username"
            type="text"
            pInputText
            class="w-full md:w-25rem text-color-secondary surface-50 border-200"
            placeholder="{{ 'REGISTER.USERNAME' | translate }}"
          />
        </span>
        <span class="p-input-icon-left w-full mb-4">
          <i class="pi pi-envelope"></i>
          <input
            id="email"
            type="text"
            pInputText
            class="w-full md:w-25rem text-color-secondary surface-50 border-200"
            placeholder="Email"
          />
        </span>
        <span class="p-input-icon-left w-full mb-4">
          <i class="pi pi-lock"></i>
          <!-- <p-password id="password" placeholder="{{ 'REGISTER.PASSWORD' | translate }}" [inputStyle]="{ paddingLeft: '2.5rem' }" inputStyleClass="w-full md:w-25rem text-color-secondary surface-50 border-200" [toggleMask]="true"></p-password> -->
          <input
            id="password"
            type="password"
            pInputText
            class="w-full md:w-25rem text-color-secondary surface-50 border-200"
            placeholder="{{ 'REGISTER.PASSWORD' | translate }}"
          />
        </span>
        <div class="mb-4 flex flex-wrap">
          <p-checkbox
            name="checkbox"
            value="val"
            [(ngModel)]="confirmed"
            styleClass="mr-2"
            [binary]="true"
          ></p-checkbox>
          <label for="checkbox" class="text-900 font-medium mr-2">{{
            "REGISTER.READ" | translate
          }}</label>
          <a
            class="text-600 cursor-pointer hover:text-primary cursor-pointer"
            >{{ "REGISTER.TERMS" | translate }}</a
          >
        </div>
        <button
          pButton
          pRipple
          label="{{ 'REGISTER.SIGNUP' | translate }}"
          class="w-full mb-4"
          [routerLink]="['/']"
        ></button>
        <span class="font-medium text-600"
          >{{ "REGISTER.ACCOUNT" | translate }}
          <a
            [routerLink]="['/login']"
            class="font-semibold cursor-pointer text-900 hover:text-primary transition-colors transition-duration-300"
            >{{ "REGISTER.LOGIN" | translate }}</a
          ></span
        >
      </div>
      <div class="mt-5">
        <button
          type="button"
          (click)="toggleDropdown()"
          style="border: none; background: transparent; cursor: pointer"
        >
          <img
            [src]="selectedLanguageIcon"
            alt="Selected Language"
            style="width: 20px; height: auto"
          />
        </button>
        @if(dropdownVisible){
        <div class="dropdown-menu" style="position: absolute; z-index: 1000">
          @for(lang of languages;track lang){
          <ng-container>
            <div
              (click)="switchLanguage(lang.value)"
              style="
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 0 0 5px 5px;
              "
            >
              <img
                [src]="lang.icon"
                alt="{{ lang.label }}"
                style="width: 15px; height: auto; margin-right: 5px"
              />
              {{ lang.label }}
            </div>
          </ng-container>
          }
        </div>
        }
      </div>
    </div>
    <div class="flex flex-wrap align-items-center pb-4 px-4">
      <h4 class="m-0 mr-5" style="line-height: 22px">PHI</h4>
      <!-- <h6 class="m-0 font-medium text-300" style="line-height: 17px">Copyright Ⓒ Covariance P.C.</h6> -->
    </div>
  </div>
</div>
