import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService, DoctorService, ImportsModule } from 'shared';
import { SidebarModule } from 'primeng/sidebar';
import { Table } from 'primeng/table';
@Component({
  selector: 'app-manage-patients',
  standalone: true,
  imports: [ImportsModule, SidebarModule],
  templateUrl: './manage-patients.component.html',
  styleUrl: './manage-patients.component.scss',
})
export class ManagePatientsComponent implements OnInit, OnDestroy {
  insured: any;
  sidebarVisible: boolean = false;
  statuses!: any[];
  value: any;
  representatives: any;

  subscriptions = new Subscription();
  item: any;
  visible: boolean = false;
  dataLoaded: boolean = false;
  constructor(
    private doctorService: DoctorService,
    private authService: AuthService
  ) {}
  ngOnInit(): void {
    this.authService.currentUser$.subscribe((doctor) => {
      if (doctor) {
        console.log(doctor);
        const subscription$ = this.doctorService
          .getAllPatientsByDoctorUserId(doctor.data.userId)
          .subscribe({
            next: (result) => {
              if (result) {
                console.log(result);
                this.insured = result.data;
                this.item = this.insured[0];
              }
            },
            error: (error) => {
              console.error('Error fetching data: ', error);
            },
            complete: () => {
              this.dataLoaded = true;
            },
          });
        this.subscriptions.add(subscription$);
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  viewDetails(item: any) {
    this.item = item;
    this.visible = true;
  }

  toggleSidebar() {
    this.sidebarVisible = true;
  }

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }
}
