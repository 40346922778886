import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthResponse } from '../../interfaces/authresponse';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');
    if (!request.url.includes('api.talkjs.com')) {
      if (token) {
        const parsedResponse: AuthResponse = JSON.parse(token);

        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${parsedResponse.jwtToken}`,
          },
        });
      }
    }

    return next.handle(request);
  }
}
