import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CalendarOptions } from '@fullcalendar/core';
import { BadgeModule } from 'primeng/badge';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
@Component({
  selector: 'lib-full-calendar',
  standalone: true,
  imports: [
    BadgeModule,
    FullCalendarModule,
    DialogModule,
    FormsModule,
    DropdownModule,
    DatePipe,
  ],
  templateUrl: './custom-calendar.component.html',
})
export class CustomCalendarComponent implements OnInit {
  @Input({ required: true }) appointments!: any;

  calendarOptions: any = {
    plugins: [dayGridPlugin],
    initialView: 'dayGridMonth',
    weekends: false,
  };

  showDialog: boolean = false;
  view: string = '';
  tags: any;
  changedEvent: any;
  clickedEvent: any = null;

  ngOnInit(): void {
    this.calendarOptions = {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      height: 720,
      initialView: 'dayGridMonth',
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay',
      },
      editable: true,
      selectable: false,
      selectMirror: true,
      dayMaxEvents: true,
      eventClick: (e: MouseEvent) => this.onEventClick(e),
      select: (e: MouseEvent) => this.onDateSelect(e),

      events: this.appointments.map((appointment: any) => {
        let startDateTime;
        if (appointment.appointmentTime) {
          startDateTime = new Date(
            `${appointment.appointmentDate}T${appointment.appointmentTime}`
          );
        } else {
          startDateTime = new Date(
            `${appointment.appointmentDate}T${appointment.time}`
          );
        }

        const endDateTime = new Date(startDateTime);
        endDateTime.setHours(startDateTime.getHours() + 1);
        let color;

        switch (appointment.appointmentStatus.appointmentStatusId) {
          case 1:
            color = '#17a2b8';
            status = 'info';
            break;
          case 2:
            color = '#dc3545';
            status = 'danger';
            break;
          case 3:
            color = '#ffc107';
            status = 'warning';
            break;
          case 4:
            color = '#dc3545';
            status = 'danger';
            break;
          case 5:
            color = '#28a745';
            status = 'success';
            break;
          default:
            color = '#dc3545';
            status = 'danger';
            break;
        }

        return {
          title: appointment.fullName,
          start: startDateTime.toISOString(),
          end: endDateTime.toISOString(),
          description: appointment.reasonOfVisit,
          color: color,
        };
      }),
    };
  }

  onEventClick(e: any) {
    this.clickedEvent = e.event;
    let plainEvent = e.event.toPlainObject({
      collapseExtendedProps: true,
      collapseColor: true,
    });
    this.view = 'display';
    this.showDialog = true;

    this.changedEvent = { ...plainEvent, ...this.clickedEvent };
    this.changedEvent.start = this.clickedEvent.start;
    this.changedEvent.end = this.clickedEvent.end
      ? this.clickedEvent.end
      : this.clickedEvent.start;
  }

  onDateSelect(e: any) {
    this.view = 'new';
    this.showDialog = true;
    this.changedEvent = {
      ...e,
      title: null,
      description: null,
      location: null,
      backgroundColor: null,
      borderColor: null,
      textColor: null,
      tag: { color: null, name: null },
    };
  }
}
