@if (chartOptions?.series) {
<div class="h-full">
  <apx-chart
    [series]="chartOptions.series"
    [labels]="chartOptions.labels"
    [chart]="chartOptions.chart"
    [xaxis]="chartOptions.xaxis"
    [yaxis]="chartOptions.yaxis"
    [plotOptions]="chartOptions.plotOptions"
    [dataLabels]="chartOptions.dataLabels"
    [title]="chartOptions.title"
    [colors]="chartOptions.colors"
  ></apx-chart>
</div>
}
